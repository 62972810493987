// Components
import SectionWithImage from "../components/UI/SectionWithImage";
import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";
import GreenSectionHeader from "../components/UI/GreenSectionHeader";
import HalfSplitSection from "../components/UI/HalfSplitSection";
import PageHeader from "../components/UI/PageHeader";
import ImageWithFallback from "../components/ImageWithFallback";
import BrandButton from "../components/UI/BrandButton";

// Images
import Governance from "../assets/images/Governance.webp";
import FBGovernance from "../assets/images/Governance.jpg";

import Skyline from "../assets/images/Eligible.webp";
import FBSkyline from "../assets/images/Eligible.jpg";

import FutureOfCarbonRemoval from "../assets/images/MagazineMock.webp";
import FBFutureOfCarbonRemoval from "../assets/images/MagazineMock.jpg";

import HowItWorksHeader from "../assets/images/banners/how-it-works-banner.webp";

const HowItWorks = () => {
    return (
        <div>
            <PageHeader headerText="How It Works" headerImg={HowItWorksHeader} />

            <SectionWithImage isLight headerText="Governance" altText="open field" img={Governance} fallbackImg={FBGovernance} content={<>
                <p className="pt-3">The C-Capsule Code (detailing the rules and regulations) is accredited by the International REC Standard Foundation, a not-for-profit organisation based in the Netherlands that administers the International Attribute Tracking Standard for best practice in attribute tracking systems. Accreditation to the Standard ensures that the tracking of products (in C-Capsule’s case, CDR) is of the highest quality and satisfies the expectations of stakeholders, market entities, and end-users. The Foundation acts as an independent body to oversee the integrity of the market and alignment of the C-Capsule Code with industry best practices.</p>

                <p className="pt-5">Facilities are initially audited to establish the metrics of capture, with the objective of a simple, low-cost measurement.</p></>}
            />

            <article className="container p-5">
                <GenericSubSectionHeader size="text-3xl" heading="Role of Market Intermediaries" />
                <p className="py-5">Organisations can either engage directly with facility operators or use market intermediaries to manage their portfolio. Market intermediaries can hold Participant accounts on the Evident Registry and are able to trade and redeem CRUs on behalf of their clients (beneficiaries). </p>
                <p className="pb-5">At the end of the process, organisations receive a redemption statement uniquely identifying all claimed CRUs, including details of the originating CDR facilities, the process used, and the dates when the CDR occurred. This statement can be viewed directly on the Evident Registry to support audit of claims.</p>

                <GenericSubSectionHeader size="text-3xl" heading="Role of Governments" />
                <p className="pt-5">Governments can have multiple roles in the C-Capsule framework such as Issuer (processing the issuance of certificates) or Redemption Authority (authorising redemption of certificates). We recognise the sovereignty of governments and will always seek to adjust the framework to accommodate the requirements of a government.</p>
            </article>

            <article className="bg-light-article">
                <GreenSectionHeader heading="Am I eligible for C-Capsule?" />
                <div className={`container flex sm:pt-5`}>
                    <HalfSplitSection
                        leftContent={
                            <section className="px-5">
                                <div className="mb-5">
                                    <p className="pb-5">Yes, if you can provide robust evidence to support your claims. The first step is to develop a methodology that complies with C-Capsule’s Methodology Guidelines. This document outlines the quality threshold for developers to register their facilities on the Evident Registry.</p>
                                    <p>All methodologies are subject to independent approval by the International REC Standard Foundation. Methodologies are assessed on their compliance with our high-quality issuing criteria:</p>
                                </div>
                                <ImageWithFallback srcImg={Skyline} fallbackImg={FBSkyline} altText="City Skyline" />
                            </section>
                        }
                        leftClasses="sm:mr-10"
                        rightContent={
                            <ul className="list-disc pl-10 pb-5 px-5 self-top">
                                <li className="pb-5"><span className="font-bold text-neutral-600">Measurable:</span> CRUs represent CDR activity which can be precisely measured. Projects adhere to methodologies with identifiable measurement points where the volume of CDR can be determined through robust and reliable metering equipment.</li>
                                <li className="pb-5"><span className="font-bold text-neutral-600">Durable:</span> CRUs demonstrate the durable sequestration of GHG that has been purposefully removed from the atmospheric cycle. Every methodology reflects a defined probability of durability set against a 100-year time horizon.</li>
                                <li className="pb-5"><span className="font-bold text-neutral-600">Verifiable:</span> CRUs are independently verified at the point of issuance to clearly identify measurable carbon sequestration and ensure no double counting. Facilities are audited prior to issuance to verify that their characteristics adhere to the associated methodology.</li>
                                <li><span className="font-bold text-neutral-600">Additional:</span> CRUs convey CDR activity that would not have otherwise happened without carbon finance. Where significant non-carbon finance exists, additionality can represent factors that require investment, presence of barriers or risks, activities which aren’t common practice.</li>
                            </ul>
                        }
                    />

                </div>

                <article className="container flex items-center p-5">
                    <HalfSplitSection
                        leftContent={
                            <div className="py-10 pr-10">
                                <ImageWithFallback srcImg={FutureOfCarbonRemoval} fallbackImg={FBFutureOfCarbonRemoval} altText="Future Of Carbon Removal PDF preview" />
                            </div>
                        }
                        leftClasses="hidden sm:block"
                        rightContent={
                            <>
                                <GenericSubSectionHeader size="text-3xl" heading="C-Capsule Introduction" />

                                <p className="my-5">C-Capsule is recognised by the International REC Standard Foundation that administers the International Attribute Tracking Standard for best practice implementation in environmental attribute tracking systems. </p>

                                <p className="mb-10">Find out more about how C-Capsule drives Net Zero aspirations by viewing our Introduction and learn about our values, governance structure, and heritage.</p>

                                <div className="my-5">
                                    <BrandButton url="/documents/C-Capsule-Introduction.pdf" isDownload btnText="Download" />
                                </div>

                            </>
                        }
                        rightClasses="self-center"
                    />
                </article>

            </article>

        </div>
    );
}

export default HowItWorks;