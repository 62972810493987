import GenericSubSectionHeader from "./UI/GenericSubSectionHeader";
import MoreArticlesSidebarCard from "./MoreArticlesSidebarLink";

const MoreArticlesSidebar = () => {
    return (
        <>
            <div className="flex flex-col m:m-0 border-l text-neutral-600">
                <div className="pl-5">
                    <div className="text-center pb-4">
                        <GenericSubSectionHeader heading="More Articles" size="text-2xl" />
                    </div>

                    <MoreArticlesSidebarCard srcUrl="/news/article/AfricaClimateWeek" headingText="Africa Climate Week 2023" subText="The Mutuma Marangu Sculpture & Art Collection, Nairobi, Kenya" />

                    <MoreArticlesSidebarCard srcUrl="/news/article/CarbonRemovalBasel2023" headingText="Carbon Removal Basel 2023" subText="Basel, Switzerland" />

                    <MoreArticlesSidebarCard srcUrl="/news/article/ISC2022" headingText="I-REC Standard Conference 2022" subText="Bangkok, Thailand" />

                    <MoreArticlesSidebarCard srcUrl="/news/article/revolutionising-the-carbon-market" headingText="How C-Capsule is Revolutionising the Carbon Market" subText="An exclusive interview with Echoz" />

                    <MoreArticlesSidebarCard srcUrl="/news/article/travis-caddy-at-IREC-Istanbul" headingText="Travis Caddy at IREC Istanbul 2022" subText="Istanbul, Türkiye" />


                </div>
            </div>
        </>
    );
};

export default MoreArticlesSidebar;
