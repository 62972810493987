import { ReactNode } from "react";
import { useLocation } from 'react-router-dom';

const PageTitle = ({ title }: { title: string | ReactNode }): JSX.Element => {

    //Used to determine if the route is an article or anything else. If its an article route, that header will be used as the H1 instead
    const location = useLocation();
    const classList = "text-brand-green text-5xl font-bold p-5 pr-[100px] leading-[1.15]";

    return (
        <div className="container mb-5">

            {location && !location.pathname.includes("article") ? <h1 className={classList}>{title}</h1> : <h2 className={classList}>{title}</h2>}
        </div>
    )
}
export default PageTitle;
