// Components
import GreenSectionHeader from "../components/UI/GreenSectionHeader";
import CompaniesAbout from "../components/UI/CompaniesAbout";
import SectionWithImage from "../components/UI/SectionWithImage";
import StaffMember from "../components/StaffMember";

// Images
import gccLogo from "../assets/images/GCC-Logo-Black.webp";
import cflLogo from "../assets/images/CFL_logo_Single_black.svg"
import evidentLogo from "../assets/images/Evident-Primary Logo-Black.svg"
import Windfarm from "../assets/images/windfarm.webp";
import FBWindfarm from "../assets/images/windfarm.jpg";
import PageHeader from "../components/UI/PageHeader";
import AboutUsBanner from "../assets/images/banners/about-us-banner.webp";

const About = () => {
    return (
        <>
            <PageHeader headerText="About us" headerImg={AboutUsBanner} />
            <div className="container mb-5">
                <h1 className="text-center text-3xl font-bold p-10 text-neutral-600">C-Capsule was co-founded by Carbon Finance Labs and Evident to scale Carbon Dioxide Removal in line with Net Zero.</h1>
            </div>

            <article className='bg-light-article flex flex-col pb-10'>
                <GreenSectionHeader heading="The Team" />
                <div className="flex flex-col lg:flex-row container mb-5 px-5">
                    <CompaniesAbout logo={evidentLogo} companyName="Evident" altText="Evident Global Logo" content={<>
                        <p className="pb-5"><a className="underline underline-offset-4 decoration-1" href="https://evident.global/" aria-label="Visit the Evident Website" target="_blank" rel="noreferrer">Evident</a> is the world leader in certification of the Clean Economy and provider of the world’s most widely used registry of environmental assets, serving customers in over 140 countries.</p> <p>Evident manages the I-REC Product Certificate for electricity and MiQ certificate service for natural gas. Evident enables clients in both commercial and public sectors to take full advantage of new technologies and become highly competitive digital organisations.</p>
                    </>} />

                    <CompaniesAbout logo={cflLogo} companyName="Carbon Finance Labs" altText="CFL Logo" content={<>

                        <p className="pb-5"><a className="underline underline-offset-4 decoration-1" href="https://carbonfinancelab.com/" aria-label="Visit the Carbon Finance Labs Website" target="_blank" rel="noreferrer">Carbon Finance Labs</a> are finance and technology professionals creating and implementing new climate change solutions.</p>
                        <p>They use their global experience in carbon finance, energy, technology and commercial development to create and implement new solutions to address climate change.Their impact comes from using a global network of resources and knowledge built over decades spent in the carbon finance and technology sectors globally. </p>
                    </>} />

                    <CompaniesAbout logo={gccLogo} companyName="GCC" altText="GCC Logo" content={<>
                        <p className="pb-5"><a className="underline underline-offset-4 decoration-1" href="https://www.gcc.re/" aria-label="Visit the GCC Website" target="_blank" rel="noreferrer">GCC</a> is one of the world’s leading independent certifiers of renewable energy. </p>
                        <p>With over twenty years’ experience they are a centre of excellence, providing support to other organisations in the certification sector and establishing best practice in the global marketplace. GCC is active in a variety of emerging environmental markets, including being a strategic partner for certification of methane emissions within the natural gas sector (<a className="underline underline-offset-4 decoration-1" href="https://miq.org/" aria-label="Visit the MIQ" target="_blank" rel="noreferrer">MIQ</a>).</p></>} />
                </div>


                <div className="container mb-5 px-5">
                    <GreenSectionHeader heading="Staff" />
                    <h2 className="text-center italic">Through collaboration and shared expertise, our teams integrate efforts to drive innovation and excellence</h2>
                    <div className="flex flex-wrap justify-center">
                        <StaffMember personName="Ed Everson" jobTitle="Chief Executive Officer" />
                        <StaffMember personName="Teresa Everson-Smith" jobTitle="Chief Strategy Officer" />
                        <StaffMember personName="Barnaby Jenkins" jobTitle="General Counsel" />
                        <StaffMember personName="Travis Caddy" jobTitle="Business Development Director" />
                        <StaffMember personName="Rebecca Morgan" jobTitle="Subject Matter Expert" />
                        <StaffMember personName="Zhiwei Chen" jobTitle="Subject Matter Expert" />
                        <StaffMember personName="Luke Greicius" jobTitle="Head of Policy" />
                        <StaffMember personName="Garrett Guard" jobTitle="Product Associate" />
                        <StaffMember personName="Theo Platts-Dunn" jobTitle="Policy Associate" />
                        <StaffMember personName="Chris Johnstone" jobTitle="Senior Developer" />
                    </div>
                </div>




            </article>

            <article className='flex flex-col pb-10'>
                <SectionWithImage headerText="Our History" img={Windfarm} fallbackImg={FBWindfarm} altText={"windfarm"} content={
                    <div>
                        <p className="py-5">C-Capsule’s team developed the first renewable energy certificate trading mechanism in Europe during the 1990s, eventually evolving into the Guarantee of Origin (GO). The team later designed and implemented the highly successful I-REC (International Renewable Energy Certificate), which is currently issued in over 40 countries.</p>
                        <p>I-REC has become the established system for evidence to support origination claims by consumers in reporting their Scope 2 and Scope 3 emissions. It is internationally recognised by NGOs and consumer organisations to provide secure and reliable electricity origination information across the chain of custody.</p>
                    </div>}
                />
            </article>


        </>
    );
}

export default About;