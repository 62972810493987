import PageHeader from "../components/UI/PageHeader";
import ContactBanner from "../assets/images/banners/contact-banner.webp";
import LocationMap from "../components/UI/LocationMap";
import { Wrapper } from "@googlemaps/react-wrapper";
import BrandButton from "../components/UI/BrandButton";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

const Contact = () => {
    return (
        <>
            <PageHeader headerText="Contact Us" headerImg={ContactBanner} />
            <div className="container">
            </div>
            <article className="bg-light-article">
                <div className="container pb-20">
                    <div className="p-5 pb-[50px]">
                        <h2 className="text-2xl pb-5 font-bold text-neutral-600">Get involved</h2>
                        <p className="pb-5 mb-2">Find out more about how C-Capsule can help you achieve your Net Zero goals or create value for carbon removal.</p>
                        <BrandButton url="mailto:info@c-capsule.com" btnText="Email us" />
                    </div>
                    {!apiKey &&
                        <div className="flex items-center justify-center w-full h-[400px] bg-neutral-300">This page can't load Google Maps correctly.</div>
                    }
                    {apiKey &&
                        <Wrapper apiKey={apiKey}>
                            <LocationMap zoom={15} center={{ lat: 53.3865235, lng: -1.5047903 }} />
                        </Wrapper>
                    }
                </div>
            </article>
        </>

    );
}

export default Contact;