import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";

const ITN = () => {
    return <>
        <GenericSubSectionHeader isMainHeader heading="ITN Team Explores Future of Durable CDR with C-Capsule" size="text-5xl" />

        <p className="pt-5">The ITN team recently visited Evident’s new headquarters to gain insights into certification of the Clean Economy with a focus on renewable energy and Carbon Dioxide Removal (CDR). This special feature focused on carbon projects and accreditation programs. Travis Caddy spoke about how C-Capsule will focus on the certification of durable CDR. You can watch the complete interview <a className="font-bold hover:underline" href="https://rb.gy/bgnto">here.</a></p>
    </>

}

export default ITN;