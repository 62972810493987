import { NavLink } from "react-router-dom";

const MoreArticlesSidebarLink = ({ srcUrl, headingText, subText }: { srcUrl: string, headingText: string, subText: string }) => {
    return (
        <NavLink to={srcUrl}>
            <div className="p-2 hover:bg-neutral-100 rounded-md mb-2">
                <h2 className="font-bold">{headingText}</h2>
                <p className="text-sm">{subText}</p>
            </div>
        </NavLink>
    );
};

export default MoreArticlesSidebarLink;
