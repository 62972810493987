import Location from '../assets/images/icons/location-dot-solid.svg';
import Email from '../assets/images/icons/envelope-solid.svg';
import FooterSocials from './FooterSocials';


const FooterContactDetails = () => {
    return <div className='flex flex-col'>
        <div className='flex text-sm items-start px-5 sm:px-0'>
            <img className={`w-[15px] fill-white mt-5 sm:mt-1 sm:w-[20px]`}
                src={Location} alt="location pin">
            </img>
            <address className='not-italic sm:px-0'>
                <address className='not-italic px-5 pt-5 sm:pt-1'>
                    <p>UK Head office</p>
                    <p>Church Studio</p>
                    <p>400 Springvale Road</p>
                    <p>Sheffield</p>
                    <p>S10 1LP</p>
                    <p className='pb-5'>United Kingdom</p>
                </address>
            </address>
        </div>

        <a href="mailto:info@c-capsule.com">
            <div className='flex items-start mx-5 mb-0 sm:mb-5 sm:mx-0 sm:hover:underline'>
                <img className={`w-[15px] fill-white mt-1 sm:w-[20px]`}
                    src={Email} alt="email">
                </img>
                <div className='ml-5'>
                    info@c-capsule.com
                </div>
            </div>
        </a>

        <div className='hidden sm:block'>
            <FooterSocials />
        </div>

    </div>
}

export default FooterContactDetails;