import Accordion from "../components/UI/Accordion";
import PageHeader from "../components/UI/PageHeader";
import FAQBanner from "../assets/images/banners/faq-banner.webp";

const FAQ = () => {
    return (<>
        <PageHeader headerText={<span>Frequently Asked<br /> Questions</span>} headerImg={FAQBanner} />
        <div className="container mb-20">

            <section className="p-6 sm:p-3">
                <Accordion
                    mainText="What are CRUs (Carbon Removal Units)?"
                    subText="CRUs are origination certificates evidencing CDR events or activities. Similar in concept to RECs and Guarantees of Origin, CRUs are tradeable attribute certificates."
                />
                <Accordion
                    mainText="What is durable Carbon Dioxide Removal (CDR)?"
                    subText="Carbon removal is the durable sequestration of carbon directly removed from the atmosphere over a minimum 100-year time horizon."
                />
                <Accordion
                    mainText="How can CRUs aid the acceleration of CDR deployment?"
                    subText="Many processes can sequester or make active use of carbon, removing it from the atmospheric cycle. However, most industrial scale processes for CDR are early-stage technologies or practices that have yet to realise scale efficiencies and financial viability. C-Capsule is a market-based solution designed to accelerate the deployment of these technologies."
                />
                <Accordion
                    mainText="What are the benefits of science-based carbon removal?"
                    subText="Whilst carbon offsetting can be measurable, it is based on a conceptual ‘business as usual’ case of avoided emissions and seeks to quantify the emissions that would otherwise have happened. Science-based targets require science-based evidence of real actions taken. Carbon markets need to evolve beyond the traditional offset approach to achieve Net Zero goals; a transition recognised by many governments, NGOs, and market actors."
                />
                <Accordion
                    mainText="How do CRUs work?"
                    subText="C-Capsule brings the measurable facts approach that is well established in renewable energy to the carbon market. Every net tonne of CO₂ captured and durably sequestered is a tonne removed from the atmospheric cycle. This activity is recorded by a unique CRU (Carbon Removal Unit) for each tonne. CRUs can be sold to businesses or governments who can then claim the associated CDR to neutralise their emissions within their climate accounting. Each CRU carries a unique serial number to guarantee no double counting or double claim. C-Capsule provides a market mechanism for generating revenue for CDR activities and can be freely traded until a claim has been made."
                />
                <Accordion
                    mainText="Am I eligible for CRUs?"
                    subText="CDR developers are required to develop a methodology adherent to C-Capsule’s Methodology Guidelines. The requirements outline the quality threshold for developers to register Facilities on the Evident Registry. All methodologies are subject to independent approval by the International REC Standard Foundation."
                />
                <Accordion
                    mainText="Does C-Capsule serve the voluntary or compliance markets?"
                    subText="C-Capsule is designed to serve markets of all kinds, with a focus on providing robust evidence for consumers and reporting authorities. Although being developed as a voluntary system, C-Capsule can be adopted by governments as a compliance tool and infrastructure to facilitate local climate change policy."
                />
                <Accordion
                    mainText="How does C-Capsule work with government policies?"
                    subText="Fiscal policies for tackling climate change generally involve carbon taxes or allowances. There are also funds effectively ‘buying’ carbon mitigation activities. C-Capsule can work alongside a tax mechanism. Where carbon taxes are applied to emitters, it may be possible that CRUs be used as a means of offsetting a tax obligation. They could also be used as the evidence against which a purchasing obligation or CDR fund payments can be managed."
                />
                <Accordion
                    mainText="Isn’t this just ‘green-washing’?"
                    subText="Ideal climate change solutions involve not emitting carbon. However, some activities will continue to emit carbon for the foreseeable future. Organisations wishing to neutralise their hard-to-abate carbon impact can purchase a mitigation service just like any other aspect of their supply chain. C-Capsule enables those organisations to do so in a secure, transparent, and trusted manner, ensuring measured environmental benefit."
                />
                <Accordion
                    mainText="How does C-Capsule interact with country NDC reporting?"
                    subText="C-Capsule aims to be complementary to any global policy related to carbon markets, particularly those regarding Article 6 of the Paris Agreement."
                />
                <Accordion
                    mainText="Are biological and ocean carbon sinks eligible?"
                    subText="Simple forestry and ocean-based biological sinks are not currently eligible for CRUs. It is intended that methodologies for these approaches will be developed in the future when approaches to measuring such activities and our understanding of their durability improve. Please contact us if you wish to support in this development."
                />
                <Accordion
                    mainText="How can I register a facility or project?"
                    subText="Project developers or their appointed agents need to become a Registrant to register CDR facilities. Any process which can provide independently verified evidence of durable CDR under an approved Methodology is eligible for C-Capsule. Facilities are initially audited to establish the metrics of CDR, with the objective of determining a simple, low-cost measurement. Direct carbon emissions from facilities (Scope 1) are audited and deducted to establish the net tonnes of carbon removed. Facilities can be registered to an existing methodology or a new methodology if none currently apply. For further information, please contact us."
                />
                <Accordion
                    mainText="How can I submit a methodology?"
                    subText="Methodologies approved by the International REC Standard Foundation are eligible for CRUs. Methodologies must be submitted to the Foundation through the Code Manager. Please contact us for further guidance and details on how to develop a methodology based on our template."
                />
                <Accordion
                    mainText="How can I be appointed as an independent auditor?"
                    subText="Auditors are referred to as ‘verification authorities’. Each methodology sets out requirements for verification authorities to be approved for the associated carbon removal process. All methodologies are subject to independent approval by the International REC Standard Foundation. A verification authority can be appointed at any time. Currently, the only approved verification authority is SGS. Please contact us for further information on the role of verification authorities."
                />
                <Accordion
                    mainText="How are appointments made to leadership, committee, and groups within C-Capsule?"
                    subText="Appointments are chosen for expertise, independence, and commitment to our mission. Our appointment processes prioritise fairness, inclusivity, and diversity, aiming for a transparent and robust system that offers equal opportunities to all."
                />
            </section>
        </div>
    </>

    );
}

export default FAQ;