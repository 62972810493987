const GenericSubSectionHeader = ({ heading, isMainHeader, size, isCentered, }: { heading: string, isMainHeader?: boolean, size?: string, isCentered?: boolean }): JSX.Element => {
    const textSize = size || 'text-xl';
    const alignmentClass = isCentered ? 'text-center' : '';

    return (
        <div className={`${textSize} ${alignmentClass} text-neutral-600 font-bold`}>
            {isMainHeader ? <h1>{heading}</h1> : <h2>{heading}</h2>}
        </div>
    );
}

export default GenericSubSectionHeader;