import LinkedIn from '../assets/images/icons/linkedin.svg'

const FooterSocials = ({ isMobileView }: { isMobileView?: boolean }) => {

    return <a href='https://www.linkedin.com/company/c-capsule/' target="_blank" rel="noreferrer" aria-label='Follow us on LinkedIn' aria-hidden="true">
        <div className='flex items-start hover:underline'>
            <img className={`${isMobileView ? "w-[25px] pb-2" : "w-[20px]"}`} src={LinkedIn} alt="LinkedIn Logo" />
            <div className={`ml-5 hidden sm:block`}>
                <p>Follow us on LinkedIn</p>
            </div>
        </div>
    </a>
}

export default FooterSocials;