import PageHeader from "../components/UI/PageHeader"
import FAQBanner from "../assets/images/banners/faq-banner.webp";
import GreenSectionHeader from "../components/UI/GreenSectionHeader";
import FeaturedArticle from "../components/FeaturedArticle";
import MoreArticles from "../components/MoreArticles";

const News = () => {
    return <>
        <PageHeader headerText="News & Events" headerImg={FAQBanner} />
        <div className="container">
            <GreenSectionHeader heading="Featured Article" />
            <FeaturedArticle />
            <MoreArticles />
        </div>
    </>
}

export default News;