import SectionWithImage from "../components/UI/SectionWithImage";
import WhyCCapsule from "../components/HomeWhyCCapsule";
import GreenSectionHeader from "../components/UI/GreenSectionHeader";
import PageHeader from "../components/UI/PageHeader";

//images
import WhatIsCCapsule from "../assets/images/WhatIsCCapsule.webp";
import FBWhatIsCCapsule from "../assets/images/WhatIsCCapsule.jpg";

import TechnologyDevelopers from "../assets/images/TechnologyDevelopers.webp";
import FBTechnologyDevelopers from "../assets/images/TechnologyDevelopers.jpg";

import Businesses from "../assets/images/Businesses.webp";
import FBBusinesses from "../assets/images/Businesses.jpg";

import MarketIntermediaries from "../assets/images/MarketIntermediaries.webp";
import FBMarketIntermediaries from "../assets/images/MarketIntermediaries.jpg";

import Governments from "../assets/images/Governments.webp";
import FBGovernments from "../assets/images/Governments.jpg";

import InsurersandRiskManagers from "../assets/images/InsurersandRiskManagers.webp";
import FBInsurersandRiskManagers from "../assets/images/InsurersandRiskManagers.jpg";

import HomeBanner from "../assets/images/banners/home-banner.webp";


const Home = () => {
    return (
        <div>
            <div className="flex flex-col">
                <PageHeader headerText={<p>The Future of<br /> Carbon Removal <br /> Certificates</p>} headerImg={HomeBanner} />

                <SectionWithImage isMainHeader headerText="What is C-Capsule?" img={WhatIsCCapsule} fallbackImg={FBWhatIsCCapsule} altText="Open field" content={
                    <><p className="pt-3">C-Capsule is a standard for durable Carbon Dioxide Removal (CDR). Unlike traditional emission avoidance or reduction offsets, C-Capsule only certifies carbon that has been durably removed from the atmospheric cycle.</p>

                        <p className="pt-3">CDR is required on a large scale to reach the Paris Agreement targets and keep 1.5 alive. Achievement of Net Zero means that carbon markets need to evolve beyond the traditional offset approach; a transition recognised by many governments, NGOs, and market actors. CRUs (Carbon Removal Units) help meet the growing demand for certified CDR activities as governments and businesses work toward Net Zero.</p>

                        <p className="pt-3">C-Capsule has an open framework and clear governance structure to provide trustworthy, universal access to the Voluntary Carbon Market for all actors. We offer a market-based solution to scale up supply by accelerating access to finance for high quality, durable CDR.</p></>}
                />

                <WhyCCapsule />

                <GreenSectionHeader heading="Who can benefit from C-Capsule?" additionalTopPadding />

                <SectionWithImage headerText="Technology Developers" img={TechnologyDevelopers} fallbackImg={FBTechnologyDevelopers} altText="CO2 tank" content={
                    <><p className="pt-3">Owners of CDR facilities can obtain a CRU for every net tonne of CO<sub>2</sub> captured and durably sequestered. Processes able to evidence independently verified CDR data are eligible for CRUs. Facilities must conform to a methodology aligned with the C-Capsule Methodology Guidelines and approved by the International REC Standard Foundation. CRUs can be sold as soon as they have been issued, providing a rapid cash return on CDR activities.</p></>}
                />

                <SectionWithImage isLight headerText="Businesses" img={Businesses} fallbackImg={FBBusinesses} altText="Shaking hands in business meeting" content={
                    <p className="pt-3">C-Capsule provides a reliable mechanism for companies to neutralise their carbon footprint toward, and beyond Net Zero. Organisations can either engage directly with facility operators or use market intermediaries to manage their portfolio.</p>}
                />

                <SectionWithImage headerText="Market Intermediaries" img={MarketIntermediaries} fallbackImg={FBMarketIntermediaries} altText="Busy workplace" content={
                    <p className="pt-3">We recognise the key role played by market intermediaries in providing sustainability reporting services to their clients. CRUs carry information about every net tonne of CO<sub>2</sub> removed, allowing intermediaries to deliver bespoke portfolio management services to their clients. As account holders, market intermediaries can manage client accounts securely and confidentially.</p>}
                />

                <SectionWithImage isLight headerText="Governments" img={Governments} fallbackImg={FBGovernments} altText="Board meeting" content={
                    <p className="pt-3">C-Capsule encourages governments to adopt common global standards for reporting. Recognising that governments are sovereign, C-Capsule services are designed to enable government authorities to seamlessly undertake any role within the service on a country-by-country basis, fully compliant with the International Attribute Tracking Standard.</p>}
                />

                <SectionWithImage headerText="Insurers and Risk Managers" altText="Large office building" img={InsurersandRiskManagers} fallbackImg={FBInsurersandRiskManagers} content={
                    <p className="pt-3">Insurers will play a critical role in carbon markets. The shift from avoidance projects to removals brings new opportunities centred around the durability of sequestered carbon. C-Capsule is designed to work with insurance companies and markets, helping manage the risk of reversal and supporting confidence in CDR technologies. This will set a baseline for high-quality carbon certificates.</p>}
                />

            </div>
        </div>
    );
}

export default Home;