import React, { useEffect, useRef } from 'react';

interface MapComponentProps {
    center: google.maps.LatLngLiteral;
    zoom: number;
}

const MapComponent: React.FC<MapComponentProps> = ({ center, zoom }) => {
    const ref = useRef<HTMLDivElement | null>(null);
    const mapRef = useRef<google.maps.Map | null>(null);
    const markerRef = useRef<google.maps.Marker | null>(null);

    useEffect(() => {
        if (!ref.current) return;

        // Check if the map has already been initialized
        if (!mapRef.current) {
            // If not, create a new map
            mapRef.current = new window.google.maps.Map(ref.current, { center, zoom });
        } else {
            // If the map already exists, just update its center and zoom
            mapRef.current.setCenter(center);
            mapRef.current.setZoom(zoom);
        }

        // If the map already exists, just update its center and zoom
        mapRef.current.setCenter(center);
        mapRef.current.setZoom(zoom);

        // Check if a marker has already been added
        if (!markerRef.current) {
            // If not, create a new marker on the map
            markerRef.current = new google.maps.Marker({ position: center, map: mapRef.current, title: "Church Studio, 400 Springvale Rd, Sheffield, S10 1LP" });
        } else {
            // If the marker already exists, just update its position
            markerRef.current.setPosition(center);
        }
    }, [center, zoom]);


    return <div className='h-[400px] mx-[25px]' ref={ref} id="map" />

};

export default MapComponent;
