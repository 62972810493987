import { useState } from 'react';
import { createPortal } from 'react-dom';
import ChangeCookiePreference from '../utilities/ChangeCookiePreference';

const CookiePreferences = ({ isPreferencesOpen }: {
    isPreferencesOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {

    const [consent, setConsent] = useState(false);

    const closePreferences = (accept?: boolean) => {
        ChangeCookiePreference(accept || consent)
        isPreferencesOpen(false)
    }

    return <>
        {createPortal(
            <div className="fixed inset-0 bg-neutral-600 bg-opacity-75 overflow-y-auto h-full w-full z-20">
                <div className='flex flex-row items-center justify-center h-screen'>
                    <div className='flex flex-col bg-white p-8 rounded m-5 sm:w-[400px]'>
                        <p className='pb-3 font-bold'>Manage Cookie preferences</p>
                        <p className='pb-3'>Your changes will take affect when you click "Save & exit" or on next page reload.</p>
                        <p className='pb-5'>To find out more about our use of cookies, please see our cookie policy.</p>

                        <div className='flex pb-10 items-center'>
                            <div className="flex items-center pr-5">
                                <label htmlFor="Analytical cookies" className="flex items-center cursor-pointer">
                                    <div className="relative">
                                        <input type="checkbox" id="Analytical cookies" className="sr-only" onChange={(e) => setConsent(e.target.checked)} />
                                        <div className="checkback block bg-neutral-400 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                    </div>
                                </label>
                            </div>
                            <p>Analytical cookies</p>
                        </div>

                        <div className='flex justify-center'>
                            <button onClick={() => closePreferences()} className={`text-white p-[1rem] px-5 py-2 bg-neutral-600 mr-3 rounded-full hover:bg-neutral-700 font-bold`}>Save & exit</button>
                            <button onClick={() => closePreferences(true)} className={`text-white p-[1rem] px-5 py-2 bg-brand-green rounded-full hover:bg-brand-green-hover font-bold`}>Accept all</button>
                        </div>

                    </div>

                </div>
            </div>,
            document.body
        )}
    </>
}

export default CookiePreferences;
