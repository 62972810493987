import BrandButton from "./UI/BrandButton";

const ArticleCard = ({ category, image, headerText, subText, btnUrl }: { category: string, image: string, headerText: string, subText?: string, btnUrl: string }) => {
    return (
        <div className="p-4 max-w-[500px] text-white m-auto md:m-0 w-full md:w-1/3 md:p-2 lg:p-5">
            <div
                className={`flex flex-col justify-between bg-neutral-800 rounded-lg h-[500px]`}
            >
                <div className="pt-4 h-1/2 bg-cover bg-center rounded-t-lg" style={{ backgroundImage: `url(${image})` }}>
                    <h2 className="text-xl font-semibold p-5 pr-8 mb-5 py-1 w-fit bg-neutral-800 text-white rounded-tr-2xl">{category}</h2>
                </div>
                <div className="p-4 flex flex-col justify-between h-1/2">
                    <div>
                        <h3 className="text-2xl font-bold pb-3">{headerText}</h3>
                        {subText && <p className="">{subText}</p>}
                    </div>

                    <div className="text-xl mb-5">
                        <BrandButton url={btnUrl} btnText="Read More" isNavLink whiteText />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ArticleCard;
