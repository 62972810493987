export type SiteRoute = {
    routeText: string, routeUrl: string, mobileOnly: boolean; hideFromMainNav?: boolean; isExternal?: boolean
}

export const routes: SiteRoute[] = [
    { routeText: "Home", routeUrl: "/", mobileOnly: false },
    { routeText: "How it works", routeUrl: "/how-it-works", mobileOnly: false },
    { routeText: "FAQ", routeUrl: "/faq", mobileOnly: true },
    { routeText: "Documents", routeUrl: "/documents", mobileOnly: true },
    { routeText: "About us", routeUrl: "/about-us", mobileOnly: false },
    { routeText: "News", routeUrl: "/news", mobileOnly: false, hideFromMainNav: false },
    { routeText: "Registry", routeUrl: "https://cc.evident.app/", mobileOnly: false, isExternal: true },
    { routeText: "Contact", routeUrl: "/contact-us", mobileOnly: true, hideFromMainNav: true },
    { routeText: "Cookie policy", routeUrl: "/cookie-policy", mobileOnly: false, hideFromMainNav: true },
];
