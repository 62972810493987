import logoWhite from '../assets/images/c-capsule-logo-white.png'

import { NavLink } from 'react-router-dom';
import FooterContactDetails from './FooterContactDetails';
import MobileNavigation from './MobileNavigation';
import FooterSocials from './FooterSocials';

import CookiePreferences from "./CookiePreferences";
import { useState } from 'react';



const date = new Date().getFullYear();

// Hides if the screensize is greater than the small breakpoint
const Footer = () => {

    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
    return (
        <footer className='bg-brand-grey pt-5 sm:flex flex-col px-5'>
            <div className='flex flex-col container text-white mb-5 justify-between
            sm:flex-row'>
                <div className='flex flex-col align-center justify-between text-sm'>
                    <MobileNavigation />
                    <img className="hidden max-w-[150px] sm:flex" src={logoWhite} alt="c-capsule logo" />
                    <div>
                        <div className='hidden hover:underline sm:block'>
                            <NavLink to="/cookie-policy">Cookie Policy</NavLink>
                        </div>

                        <div className='border-b border-slate-400 pb-3 mx-5 sm:m-0 sm:pb-0 sm:border-none'>
                            <span className='mb-2 hover:underline cursor-pointer lg:mb-0'
                                onClick={() => { setIsPreferencesOpen(true) }}>Cookie Preferences</span>
                        </div>

                        <p className='hidden sm:block'>Copyright C-Capsule 2023</p>
                    </div>
                </div>

                <FooterContactDetails />
            </div>

            <div className='flex flex-row justify-between items-center border-t p-5 border-slate-400 text-white sm:hidden'>
                <img className='w-1/4 pb-2' src={logoWhite} alt="c-capsule logo" />
                <div className='flex flex-col items-end'>
                    <FooterSocials isMobileView />
                    <p className='text-xs'>Copyright C-Capsule {date}</p>
                </div>
            </div>
            {isPreferencesOpen &&
                <CookiePreferences isPreferencesOpen={setIsPreferencesOpen} />
            }

        </footer>
    )
}

export default Footer;