import GenericSubSectionHeader from "./GenericSubSectionHeader"

const CompaniesAbout = ({ companyName, content, logo, altText }: { companyName: string, content: JSX.Element | string, logo: string, altText: string }) => {
    return (
        <section className="container basis-1/3 sm:px-10 flex flex-col items-center ">
            <img className="h-24 mb-5 opacity-75 mt-5" src={logo} alt={altText} />
            <GenericSubSectionHeader heading="Co-Founder" />
            <GenericSubSectionHeader heading={companyName} />
            <div className="pt-5">{content}</div>
        </section>
    );
}

export default CompaniesAbout;
