const BlogImage = ({ image, alt, caption, credit }: { image: string, alt: string, caption?: string, credit?: string }) => {
    return <div className="w-full py-5">
        <img className={`${caption || credit ? "rounded-t-md" : "rounded-md"} w-full`} src={image} alt={alt}></img>

        {(caption || credit) &&
            <div className={`bg-neutral-800 p-2 text-xs text-white rounded-b-md ${(caption || credit) && 'flex justify-between flex-col md:flex-row'}`}>
                {caption && <div>{caption}</div>}
                {credit && <div>Credit: {credit}</div>}
            </div>}
    </div>
}

export default BlogImage;