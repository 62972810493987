import ArticleCard from "./ArticleCard";
import GreenSectionHeader from "./UI/GreenSectionHeader";

//images
import ClimateWeek from "../assets/images/blog/AfricaClimateWeek_4.jpg";
import Basel2023 from "../assets/images/blog/Basel2023_1.jpg"
import isc2020 from "../assets/images/blog/isc2020Card.jpg"
import TravisCaddy from "../assets/images/blog/TravisCaddy.png"
import istanbul2022 from "../assets/images/blog/Istanbul2022.png"
import consultation from "../assets/images/blog/consultation.png"

const MoreArticles = ({ insideArticle }: { insideArticle?: boolean }) => {

    return (
        <>
            <GreenSectionHeader heading={`${insideArticle ? "More Articles" : "Read More"}`} />
            <div className="flex justify-between flex-col md:flex-row m:m-0 break-normal">
                <ArticleCard category="Document" image={consultation} headerText="Consultation" btnUrl="/news/article/Consultation" subText="Changes to the I-TRACK(CDR)/C-Capsule Code" />
                <ArticleCard category="Event" image={Basel2023} headerText="Carbon Removal Basel 2023" subText="Basel, Switzerland" btnUrl="/news/article/CarbonRemovalBasel2023" />
                <ArticleCard category="Event" image={isc2020} headerText="I-REC Standard Conference 2022" subText="Bangkok, Thailand" btnUrl="/news/article/ISC2022" />
            </div>


            <div className="flex flex-col md:flex-row m:m-0">
                <ArticleCard category="Talk" image={istanbul2022} headerText="Travis Caddy at IREC Istanbul 2022" btnUrl="/news/article/travis-caddy-at-IREC-Istanbul" />
                <ArticleCard category="Event" image={TravisCaddy} headerText="How C-Capsule is Revolutionising the Carbon Market" btnUrl="/news/article/revolutionising-the-carbon-market" />
            </div>
        </>

    );
};

export default MoreArticles;
