import { ReactNode } from 'react';
import GenericSubSectionHeader from "./UI/GenericSubSectionHeader";

const WhySection = ({ img, alt, content, header }: { img: string, alt: string, content: ReactNode, header: string }) => {
    return (
        <div className='flex flex-col w-4/5 py-5 items-center flex-center md:w-80 md:p-8'>
            <img className="w-24 h-24 mb-5" src={img} alt={alt} />
            <GenericSubSectionHeader heading={header} size="text-2xl" isCentered />
            <div className="pt-5 text-center">{content}</div>
        </div>
    )
}

export default WhySection;
