import { NavLink } from "react-router-dom";
import { routes } from "../utilities/Routes"

const MobileNavigation = ({ isMobileNav, isExpanded }: { isMobileNav?: boolean, isExpanded?: boolean }) => {
    /* The below hides the bottom nav at above the small breakpoint as we change to a different footer view 
        but we still want to keep the mobile nav an option until the md breakpoint
    */
    const hideFooterNavigation = !isMobileNav && "sm:hidden";

    const showHamburgerNav = isExpanded ? "flex" : "hidden";

    const linkStyle = (isActive: boolean, removeBorder: boolean) => {
        let baseStyles = `${!removeBorder && "border-b border-slate-400"}  w-full  lg:border-none ${isMobileNav ? "py-5" : "py-3 sm:py-1"}`

        if (isMobileNav) {
            baseStyles += ' text-xl font-bold';
        }

        if (isActive) {
            baseStyles += ' text-brand-green';
        } else if (isMobileNav) {
            baseStyles += ' text-neutral-600';
        }

        return baseStyles;
    }

    return (<div className={`px-5 lg:hidden w-full ${hideFooterNavigation} container`}>

        <h1 className={`font-bold border-b border-slate-400 py-2 ${isMobileNav && "hidden"}`}>Navigation</h1>

        <nav className={`m:flex text-sm sm:mb-[48px] flex flex-col w-full ${isMobileNav && showHamburgerNav} `}>
            {routes.map((siteRoute, idx) => (
                (isMobileNav || !siteRoute.mobileOnly) && (
                    !siteRoute.isExternal ?
                        <NavLink
                            key={siteRoute.routeUrl}
                            className={({ isActive }) => `${linkStyle(isActive, idx < routes.length - 1 ? false : true)} ${isMobileNav && "pt-5"}`}
                            to={siteRoute.routeUrl}
                        >
                            {siteRoute.routeText}
                        </NavLink>
                        :
                        <a key={siteRoute.routeUrl} className={linkStyle(false, false)} href={siteRoute.routeUrl}>{siteRoute.routeText}</a>
                )
            ))}
        </nav>
    </div>

    );
}

export default MobileNavigation;