const HalfSplitSection = ({ leftClasses, leftContent, rightClasses, rightContent }: { leftClasses?: string, leftContent: JSX.Element, rightClasses?: string, rightContent: JSX.Element }) => {

    return (
        <section className="flex flex-col md:flex-row">
            <div className={`md:w-1/2 ${leftClasses ? leftClasses : ""}`}>{leftContent}</div>
            <div className={`md:w-1/2 ${rightClasses ? rightClasses : ""}`}>{rightContent}</div>
        </section>
    )
}

export default HalfSplitSection;