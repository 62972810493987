import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";

const TEDTalk = () => {
    return <>
        <GenericSubSectionHeader isMainHeader heading="Take Back your Climate Power: TED Talk by Nick Gogerty" size="text-5xl" />

        <p className="pt-5">Meet Nick Gogerty, Managing Director of Carbon Finance Labs and co-founder of C-Capsule. In his recent TED talk at Danubia, Nick shed light on the pressing need for us to harness our climate power. He reminded his audience that reversing climate change is a collective endeavour, and that with each passing year, we possess the potential to inch closer to a sustainable future for all. The question is: Are we there yet? The answer lies within our choices and actions. To delve deeper into this vital topic, you can watch the complete TED talk here and discover how to take control of our climate power: </p>

        <div className="py-10">
            <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/W4Gv54V8YPA?si=my8zzg97Eh8mozFB" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

    </>

}

export default TEDTalk;