import { NavLink, useLocation } from "react-router-dom";
import MobileNavigation from "./MobileNavigation";
import { useEffect, useState } from "react";
import { routes } from "../utilities/Routes";
import { Squash as Hamburger } from 'hamburger-react'
import BrandButton from "./UI/BrandButton";

import logo from '../assets/images/C-Capsule-Logo.png';

const TopNavigation = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const location = useLocation();
    const showHamburgerNav = isExpanded ? "flex" : "hidden";

    const navLinkClasses = (isActive: boolean) => {
        let navLinkStyles = `p-[0.75rem] lg:p-[1rem] `;
        return isActive ? navLinkStyles += `text-brand-green` : navLinkStyles += `hover:text-neutral-900`;
    }

    useEffect(() => {
        // This will trigger the bottom disconnected nav to also cause the mobileNavigation to close
        setIsExpanded(false);
    }, [location]);

    return (
        <header className="sticky top-0 bg-white drop-shadow-md z-10 md:flex">
            <div className="container flex flex-col justify-between py-3 items-center">
                <div className="flex flex-row justify-between w-full items-center">
                    <NavLink to="/">
                        <img className="nav-logo p-2 pr-5 max-h-[55px] lg:max-h-[65px]" src={logo} alt="c-capsule logo" />
                    </NavLink>

                    <div className="lg:hidden"  >
                        <Hamburger rounded toggled={isExpanded} onToggle={() => setIsExpanded(prevIsExpanded => !prevIsExpanded)} label="Toggle mobile navigation" size={26} duration={0.25} />
                    </div>

                    <nav className="hidden lg:flex items-center font-semibold text-brand-grey">
                        {routes.map((siteRoute, idx) => (
                            !siteRoute.hideFromMainNav && (
                                !siteRoute.isExternal ?
                                    <NavLink
                                        key={`${siteRoute.routeUrl}-${idx}`}
                                        className={({ isActive }) => navLinkClasses(isActive)}
                                        to={siteRoute.routeUrl}
                                    >
                                        {siteRoute.routeText}
                                    </NavLink>
                                    :
                                    <a key={`${siteRoute.routeUrl}-${idx}`} className={navLinkClasses(false)} href={siteRoute.routeUrl}>{siteRoute.routeText}</a>
                            )
                        ))}
                        <BrandButton url="/contact-us" btnText="Contact" isNavLink />
                    </nav>
                </div>

                <div className={`${showHamburgerNav} w-full fixed mt-[66px] bg-white`}>
                    <MobileNavigation isMobileNav isExpanded />
                </div>
            </div>
        </header >
    );
}

export default TopNavigation;
