import GreenSectionHeader from "./UI/GreenSectionHeader";
import WhySection from "./WhySection";

// images
import Efficient from '../assets/images/icons/icon-efficient-reliable.png';
import Impact from '../assets/images/icons/icon-impct-based.png';
import Science from '../assets/images/icons/icon-science-based.png';
import Durable from '../assets/images/icons/icon-durable-sequestration.png';
import ThirdParty from '../assets/images/icons/icon-third-party-verified.png';
import Compliance from '../assets/images/icons/icon-supporting-compliance.png';
import FairTreatment from '../assets/images/icons/icon-supporting-fair-treatment.png';


const WhyCCapsule = () => {
    return <article className='bg-light-article'>
        <GreenSectionHeader heading="Why C-Capsule?" />

        <section className='container flex flex-wrap justify-center'>

            <WhySection img={Efficient} alt="Efficient and Reliable Icon" header="Efficient and Reliable" content="C-Capsule provides an easy to implement solution that addresses the “burden of proof” challenge encountered in conventional carbon offset markets. C-Capsule reduces the cost and increases the credibility of voluntary carbon action by supporting durable CDR solutions." />

            <WhySection img={Science} alt="Science-Based Icon" header="Science-Based" content="C-Capsule allows an evidence-based choice for CDR activities from a preferred technology or process. CRUs (Carbon Removal Units) are uniquely attributable to verified facts relating to the durable sequestration of atmospheric carbon." />

            <WhySection img={Impact} alt="Impact-Based Icon" header="Impact-Based" content="Universal methodologies can be problematic when evidencing complex or unique processes. We set clear eligibility criteria for technology developers to obtain CRUs, providing opportunities to participate in the market through use of robust methodologies for every solution." />

            <WhySection img={Durable} alt="Durable Sequestration Icon" header="Durable Sequestration" content={<p>Developers must evidence both the capture and sequestration of CO<sub>2</sub>. C-Capsule assures consumers of the durability of carbon sequestered over a 100-year time horizon.</p>} />

            <WhySection img={ThirdParty} alt="Third Party Verified Icon" header="Third Party Verified" content="CRUs are independently verified at the point of issuance based on measured CDR activity. Each facility is third party audited prior to issuance to ensure adherence with the relevant methodology." />

            <WhySection img={Compliance} alt="Supporting Compliance Icon" header="Supporting Compliance" content="C-Capsule is designed to support consumer claim standards such as the Integrity Council for the Voluntary Carbon Market (ICVCM), Science Based Target initiative (SBTi), the Voluntary Carbon Market Initiative (VCMI), CDP, and others to help consumers fulfil corporate sustainability claims such as Net Zero emissions." />

            <WhySection img={FairTreatment} alt="Supporting Fair Treatment Icon" header="Supporting Fair Treatment" content="The C-Capsule service is provided under a universal tariff to ensure non-discrimination and a ‘level playing field’ in the marketplace. Our service providers are not permitted to participate in the C-Capsule market or have any direct interest in the price of CRUs." />

        </section>

    </article>

}

export default WhyCCapsule;