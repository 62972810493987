const Consultation = () => {
    return <div>
        <h1 className="text-5xl text-neutral-600 font-bold overflow-hidden">
            Consultation on changes to the <span className="whitespace-nowrap">I-TRACK Code</span>
        </h1>


        <p className="py-5">The International Tracking Standard Foundation (I-TRACK Foundation) and C-Capsule, as the Accredited Code Manager, have released the I-TRACK for Carbon Dioxide Removal Product Code [I-TRACK(CDR)] or C-Capsule Code for CDR.  </p>

        <p className="pb-5">This, along with other governance documents, provides clear guidelines for organisations to participate in the I-TRACK(CDR) tracking system and market.

            We have opened a public consultation to gather feedback on recent changes made to the I-TRACK(CDR) Product Code. This consultation aims to inform stakeholders of the changes and gather your feedback to help us improve the I-TRACK(CDR) Product Code based on your input. </p>

        <p className="pb-5">Please send all feedback to secretariat@irecstandard.org. Please include your full name, organisation, and position title when providing feedback.

            The consultation will close on 12 July 2024.</p>

        <p className="pb-5">After the consultation closes, a public report will be published explaining the feedback received and any changes made to the I-TRACK(CDR) Product Code.  </p>

        <p className="pb-5">Please find the link to the I-TRACK(CDR) Product Code with the changes integrated <a href="https://www.trackingstandard.org/wp-content/uploads/C-CapsuleCodeCDR_v1.1_Consultation-1.pdf" className="font-bold hover:underline" target="_blank" rel="noreferrer" aria-label='additional information on the I-TRACK(CDR) Product Code'>here.</a></p>

        <p>
            Please find the link to the explanatory document providing additional information on the I-TRACK(CDR) Product Code and the changes <a href="https://www.trackingstandard.org/wp-content/uploads/Explanatory-Document-on-Code-Version-1.1-1.pdf" className="font-bold hover:underline" target="_blank" rel="noreferrer" aria-label='explanatory document on the I-TRACK(CDR) Product Code'>here.</a></p>
    </div>

}

export default Consultation;