import { useEffect } from 'react';
import { install } from 'ga-gtag';
import { getConsent } from './CookieConsent';

const GoogleAnalytics = () => {

    const gaTrackingId = 'G-2N1SJ8FE6F';

    useEffect(() => {
        install(gaTrackingId); // Initialize GA on component mount
        window[`ga-disable-${gaTrackingId}`] = true;// Set GA to disabled by default
    }, []);

    if (getConsent() === "granted") {
        window[`ga-disable-${gaTrackingId}`] = false; // Consent was granted, share data with GA
    } else {
        window[`ga-disable-${gaTrackingId}`] = true; // Disable tracking data by default
    }

    return null; // This component doesn't render anything
};

export default GoogleAnalytics;