import './App.css';
import { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

// UI elements
import Footer from './components/Footer';
import TopNavigation from './components/TopNavigation';

// Routes
import Home from './pages/Home';
import About from './pages/About';
import HowItWorks from './pages/HowItWorks';
import Documents from './pages/Documents';
import CookiePolicy from './pages/CookiePolicy';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import ScrollToTop from './utilities/ScrollToTop';
import Fourohfour from './pages/404';
import News from './pages/News';
import NewsArticle from './components/NewsArticle';
import CookieBanner from './components/CookieBanner';

// Helpers
import GoogleAnalytics from './utilities/GoogleAnalytics';

function App() {
  const location = useLocation();

  useEffect(() => {
    // Get the route's pathname and update the document title accordingly
    const pathName = location.pathname;
    document.title = getPageTitle(pathName);
  }, [location]);

  const getPageTitle = (pathName: string) => {
    const defaultTitle = 'C-Capsule';

    // Check if the path matches the news article route
    if (pathName.startsWith('/news/article/')) {
      return `${defaultTitle} - News & Events`;
    }

    // Define other route titles as before
    const pageTitleMap: Record<string, string> = {
      '/': 'Home',
      '/how-it-works': 'How It Works',
      '/documents': 'Documents',
      '/about-us': 'About Us',
      '/cookie-policy': 'Cookie Policy',
      '/contact-us': 'Contact Us',
      '/faq': 'FAQ',
      '/news': 'News & Events'
    };

    const pageTitle = pageTitleMap[pathName] || '404';

    return `${defaultTitle} - ${pageTitle}`;
  };

  return (
    <div className="App flex flex-col min-h-screen justify-between">
      <TopNavigation />
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="how-it-works" element={<HowItWorks />} />
          <Route path="documents" element={<Documents />} />
          <Route path="about-us" element={<About />} />
          <Route path="cookie-policy" element={<CookiePolicy />} />
          <Route path="contact-us" element={<Contact />} />
          <Route path="FAQ" element={<FAQ />} />
          <Route path="news" element={<News />} />
          <Route path="news/article/:articleId" element={<NewsArticle />} />
          <Route path="*" element={<Fourohfour />} />
        </Routes>
      </ScrollToTop>
      <Footer />
      <CookieBanner />
      <GoogleAnalytics />
    </div>
  );
}

export default App;
