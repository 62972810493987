import BlogImage from "../components/BlogImage";
import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";

//images
import ClimateWeek_1 from "../assets/images/blog/AfricaClimateWeek_1.jpg"
import ClimateWeek_2 from "../assets/images/blog/AfricaClimateWeek_2.jpg"
import ClimateWeek_3 from "../assets/images/blog/AfricaClimateWeek_3.jpg"
import ClimateWeek_4 from "../assets/images/blog/AfricaClimateWeek_4.jpg"

const AfricaClimateWeek = () => {
    return <div>
        <GenericSubSectionHeader isMainHeader heading="Sculpting a Net Zero Future at Africa Climate Week 2023" size="text-5xl" />

        <p className="py-5">On 5 September, as part of Africa Climate Week 2023, C-Capsule attended a remarkable event at the Nairobi National Museum's Mutuma Marangu Sculpture and Art Collection (TMMSAC). This event wasn't a typical art exhibition; it was a celebration of the connection between art and fighting climate change.</p>

        <BlogImage image={ClimateWeek_1} alt="Outside at Africa Climate Week" />

        <p className="pb-5">What made this gathering truly unique was its focus on sculptures meticulously crafted from Basalt Rock—a material with an extraordinary ability to capture and store carbon. Basalt Rock facilitates a process known as carbon mineralisation, which is a particularly notable avenue for Carbon Removal in Kenya. The CO<sub>2</sub> for this process can be sourced from the air through Direct Air Capture (DAC) technology, and then mineralised into basalt.</p>

        <blockquote className="p-4 my-4 border-l-4 border-brand-green bg-gray-100 ">
            <p className="text-xl italic font-medium leading-relaxed text-neutral-600">The exhibition symbolised a powerful synergy between artistic creativity and the strong potential for Carbon Removal in Kenya</p>
        </blockquote>

        <p className="pb-5">DAC is known for its significant energy requirements and the methodology hinges on three critical factors for success, namely, the availability of suitable geological formations for carbon storage, access to renewable energy sources, and a robust pool of talented engineers. Kenya has all three, which makes it a promising hub to scale DAC!</p>

        <BlogImage image={ClimateWeek_2} alt="Inside the Nairobi National Museum" caption="From left: Travis Caddy, Enam Akoetey-Eyiah, Tinashe Chinembiri, Ed Everson" />

        <p className="pb-5">The exhibition, therefore symbolised a powerful synergy between artistic creativity and the strong potential for Carbon Removal in Kenya through DAC and carbon mineralisation.</p>

        <p className="pb-5">The event was hosted by Evident and included a diverse array of guests, and in addition to C-Capsule included organisations such as the International REC Standard Foundation, Direct Air Capture Coalition, Octavia Carbon, Cella, Olsuswa Energy Limited, Rethinking Removals, The Open Air Collective, Climate Action Platform for Africa (CAP-A), Africa Climate Ventures, Rubicon, International Emissions Trading Association (IETA), and CUR8, amongst others.</p>

        <BlogImage image={ClimateWeek_3} alt="Conversations at Africa Climate Week" />

        <p className="pb-5">The exhibition demonstrated that art has the power and potential to not only foster creativity, but also showcase innovative solutions to world problems such as climate change. It left attendees with thoughts on how art can transcend aesthetics and serve as a catalyst for positive change.</p>

        <p className="pb-5">In a world where collaboration and innovation are extremely important, events such as these remind us that by combining our strengths, we can forge a path toward a cleaner, more sustainable future. The sculptures of Basalt Rock, standing as testament to this belief, offer a glimpse into what is possible when art and science unite to create a healthier, cleaner, and more beautiful planet for us to live on.</p>

        <BlogImage image={ClimateWeek_4} alt="Ed Everson and Mutuma Marangu at Africa Climate Week" caption="From left: Ed Everson and Mutuma Marangu" />

        <div className="pb-5 text-xs italic">
            <p>Reference:</p>
            <p></p>Benjamin, A. (no date) How direct air capture can help solve Kenya’s energy access problem, illuminem. Available at: <a href="https://illuminem.com/illuminemvoices/how-direct-air-capture-can-help-solve-kenyas-energy-access-problem">https://illuminem.com/illuminemvoices/how-direct-air-capture-can-help-solve-kenyas-energy-access-problem </a>(Accessed: 27 September 2023).
        </div>

    </div>

}

export default AfricaClimateWeek;