import TextBlockHeader from "../components/UI/TextBlockHeader";
import TextBlockSubHeader from "../components/UI/TextBlockSubHeader";

const CookiePolicy = () => {
    return (
        <div className="container p-5">
            <div className="col text-content-block" id="text-content-block-1">

                <p className="text-neutral-600 font-bold pb-5">This Cookie Policy was last updated on 24 January 2023</p>

                <TextBlockHeader heading="What do I need to know about cookies?" headingStyle="cookie" />

                <p className="pb-5">A cookie is a small text file that’s stored on your computer or mobile device when you visit a website. The most used cookies are ‘analytical’ and allow website owners to gather anonymous information about how their website is used so that they may improve your experience. For example, they can count the number of visitors to the site or improve the user experience based on how easily users are able to find content. Other cookies may be used to help manage the functionality of the site or to remember any preferences you have selected. Additional cookies may be placed on your computer by other partner companies whose services are used within our sites (e.g. for the delivery of online video or sharing links).</p>

                <TextBlockSubHeader heading="Different types of cookies" headingStyle="cookie" />

                <h3 className="text-neutral-600 font-bold">First party cookies</h3>

                <p className="pb-5">
                    These are set by the website you’re visiting and only that website can read them. A website might also use a separate company to analyse how people are using their site, and this separate company will set their own cookie to do this.</p>

                <h3 className="text-neutral-600 font-bold">Third party cookies</h3>

                <p className="pb-5">
                    These are set by someone other than the owner of the website you’re visiting. Some of the C-Capsule Limited webpages may also contain content from other sites like Google, which may set their own cookies.</p>

                <h3 className="text-neutral-600 font-bold">Advertising cookies</h3>

                <p className="pb-5">
                    Some websites use advertising networks to show you specially targeted adverts when you visit. These networks may also be able to track your browsing across different sites.</p>

                <h3 className="text-neutral-600 font-bold">Session cookies</h3>

                <p className="pb-5">
                    These are stored while you’re browsing. They get deleted from your device when you close your browser, e.g. Internet Explorer or Safari.</p>

                <h3 className="text-neutral-600 font-bold">Persistent cookies</h3>

                <p className="pb-5">
                    These are saved on your computer and don’t get deleted when you close your browser. We use persistent cookies when we need to know who you are for more than one browsing session.</p>

                <TextBlockSubHeader heading="How C-Capsule Limited uses cookies" headingStyle="cookie" />

                <p className="pb-5">We use different types of cookies for different things. Below you can find a table of all the cookies our website currently uses and why we use them.</p>

                <div className="pb-5">
                    <table className="w-full cookie-table">
                        <tbody>
                            <tr>
                                <td className="text-neutral-600 font-bold">Cookie</td>
                                <td className="text-neutral-600 font-bold">Name</td>
                                <td className="text-neutral-600 font-bold">Use</td>
                            </tr>
                            <tr>
                                <td>C-Capsule Limited</td>
                                <td>PHPSESSID</td>
                                <td>These cookies are used to store all session information for website functionality, e.g. this cookie will save your cart and is used to keep you logged in when you close the website. Blocking this cookie would result in the website being unusable.</td>
                            </tr>
                            <tr>
                                <td>DoubleClick (Google)</td>
                                <td>IDE<br />1P_JAR<br />CONSENT<br />NID<br />DV<br />UULE</td>
                                <td>These cookies are used by Google’s child company, Double Click, to track user interactions by users clicking on our website through Google’s search results.</td>
                            </tr>
                            <tr>
                                <td>Google Analytics</td>
                                <td>_ga<br />_gat<br />_gid<br />_gac_UA_[number]</td>
                                <td>These cookies are used to collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. The cookies collect information in an anonymous form, which includes the number of visitors to the site, where visitors have come to the site from, and the pages they visited.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                <TextBlockSubHeader heading="Managing and deleting cookies" headingStyle="cookie" />

                <p className="pb-5">Cookies can be monitored and deleted from your browser. Since browsers create and store cookies, they are also the most direct way to access, manage, and delete cookies. Please keep in mind that if you disable all cookies, certain portions of our website may not work properly since certain necessary cookies and functionality cookies will be disabled.</p>

                <p className="pb-5">For cookies that track activity in connection with targeting advertising, you can opt-out of third party targeting cookies on the Digital Advertising Alliance’s consumer choice page <a href="http://www.aboutads.info/choices/">here</a>, the Network Advertising Initiative’s consumer choice page <a href="http://optout.networkadvertising.org/#!/">here</a>, or the European Interactive Digital Advertising Alliance consumer choice page <a href="http://www.youronlinechoices.eu/">here</a>. Other tools offered by third parties can help you see and manage the types of cookies and trackers that you encounter. More information about cookies and cookie management can be found on <a href="http://allaboutcookies.org/">allaboutcookies.org</a> and <a href="http://www.aboutcookies.org/">aboutcookies.org</a>.</p>

                <TextBlockHeader heading="Questions and contact information" headingStyle="cookie" />

                <p>Data collected by cookies are usually anonymous. For more information on how personal data is processed in the course of a commercial relationship with C-Capsule Limited, please see our privacy policy.</p>

                <p className="pt-5">For any questions or for further information, please email us at <a href="mailto:info@c-capsule.com">info@c-capsule.com</a>.</p>

                <p className="pt-5">Your data security and privacy are important to us. Evident ensures integrity and confidentiality in the process of collecting and storing personal data. We will only use and collect personal information that is necessary for our business operations, and it will be handled and used in accordance with our Privacy Policy.</p>

                <p className="pt-5 pb-5">This privacy policy covers the processing of your data while using this website as well as a guide to how we process data in the course of a commercial relationship with C-Capsule Limited. This Privacy policy complies with the EU GDPR, UK GDPR and Data Protection Act, and global data protection legislation and best practices.</p>

                <TextBlockHeader heading="Contact Details" headingStyle="cookie" />
                <TextBlockSubHeader heading="Registered Office" headingStyle="cookie" />

                <p className="pb-5">Church Studio<br />
                    400 Springvale Road<br />
                    Sheffield<br />
                    S10 1LP<br />
                    United Kingdom</p>

                <p className="pb-5">General Enquiries: <a href="mailto:helpdesk@c-capsule.com">helpdesk@c-capsule.com</a></p>

                <p className="pb-5">Privacy/Data processing enquires: <a href="mailto:info@c-capsule.com">info@c-capsule.com</a></p>

                <TextBlockHeader heading="Definitions" headingStyle="cookie" />

                <p className="pb-5">DPO: Data Protection Officer</p>

                <p className="pb-5">EU GDPR: European Union General Data Protection Regulations</p>

                <p className="pb-5">Personal information: Data that can identify an individual and can include but is not limited to: a person’s name, address, email address, phone number. Personal data does not include encoded and anonymised data.</p>

                <p className="pb-5">Site User: The Site User refers to the website user who is not necessarily in a commercial relationship with us.</p>

                <p className="pb-5">Subject Access Request: Subject Access Request is a request made by you to our DPO about what data we have on you as well as to request the status of our processing of your data.</p>

                <p className="pb-5">The Site: The word refers to our websites.</p>

                <p className="pb-5">UK GDPR: United Kingdom General Data Protection Regulations (This differs from the Data Protection Act 1998 which supplements the UK GDPR.)</p>

                <p className="pb-5">We/Our/Us: These words refer to C-Capsule Limited.</p>

                <p className="pb-5">You/Your/Data Subject: These words refer to a site user, an individual in a commercial relationship with us, or an employee or agent of a company/business in a commercial relationship with us. Please note, a data subject can fit more than one of these criteria.</p>

                <p className="pb-5">Please note that capitalised terms otherwise not included in the list above refer to definitions included in the C-Capsule Code for CDR or International Attribute Tracking Standard.</p>

                <TextBlockHeader heading="Principles" headingStyle="cookie" />

                <p className="pb-5">We process your data in accordance with the principles of:</p>

                <ul className="list-disc pl-10 pb-5">
                    <li>Fairness, lawfulness, and transparency.</li>
                    <li>Collection of data for specific, explicit, and legitimate purposes.</li>
                    <li>Adequacy, relevance, and limitation of data to what is necessary.</li>
                    <li>Accuracy of data and facilitating rectification of inaccurate data.</li>
                    <li>Reasonable retention of data for no longer than is necessary.</li>
                    <li>Security of data</li>
                    <li>Safeguarding data during transfers.</li>
                </ul>

                <TextBlockHeader heading="Legal basis for data collection and processing" headingStyle="cookie" />

                <p className="pb-5">As a site user, the legal basis for collecting and processing data about the use of the website is our legitimate interest. As an individual in a commercial relationship with us, the legal basis for collection and processing of your data is part of our contractual obligation. For employees or agents of a company in a commercial relationship with us, we rely on our legitimate interest arising from the contract between your employer or agent and us as the legal basis for the collection and processing of your personal data. For individuals who have been in communication with us beyond the website but are not yet in a commercial relationship with us, the legal basis for processing your data is our legitimate interests and your consent if the contact was initiated by you.</p>

                <TextBlockHeader heading="Information we collect, how we collect it, and how it is used" headingStyle="cookie" />

                <p className="pb-5">We collect information with regards to how the Site User uses the Site, which is usually anonymous and helps to provide information as to the ease of use, accessibility, and performance of the website. We also collect information from individuals at the start of a commercial relationship, which may include name, address, email, phone number, national identification documents, and work information. This information is required at the start of a commercial relationship to facilitate the provision of our services to you. This information is usually collected through the appropriate application forms on the Issuer or Code Manager’s website.</p>

                <p className="pb-5">When you send communications to us, we may retain and use these communications in order to process your enquiries, respond to your requests, and improve our services.</p>

                <p className="pb-5">We will share this information with other organisations, if necessary for the provision of our service to you or in facilitating the exercise of your right to portability, where that applies. Please see the Business Transfers section of this policy for more information.</p>

                <p className="pb-5">It is our policy not to use or disclose confidential or non-public information received from clients except in connection with the provision and improvement of our services according to our contract. Such uses may include, for example, those that are usual, appropriate, or acceptable to carry out the service for which the information was given.</p>

                <p className="pb-5">We do not disclose confidential or non-public information to third parties for their direct marketing purposes nor do we endorse or promote offers from third party advertisers.</p>

                <p className="pb-5">We may from time to time, in accordance with this Privacy Policy, use personal, confidential, or non-public data to inform a client or customer about products and services that we expect may be of interest to them. Individuals may object to any such data being used for such marketing purposes by contacting us in writing at the address or email below.</p>

                <TextBlockHeader heading="External websites" headingStyle="cookie" />

                <p className="pb-5">Our website occasionally links to other websites for additional information. C-Capsule Limited does not control and is not responsible for how these external websites collect and use information. This privacy statement applies only to our own domain, <a href="http://www.c-capsule.com">www.c-capsule.com</a>.</p>

                <TextBlockHeader heading="Third Party Processors" headingStyle="cookie" />

                <p className="pb-5">We process all the data we collect through Evident Ev Limited who adhere to data protection legislation in the UK, EU, and globally. Additional safeguards are put in place by Evident Ev Limited to ensure that data is kept securely, processed in line with this policy, and protected where international transfers take place.</p>

                <p className="pb-5">Evident Ev Limited stores and processes information using infrastructure of cloud service providers. Evident Ev Limited has ensured that it uses a cloud service provider whose compliance with data protection legislation is robust enough to meet its requirements. Safeguards such as encryption are also in place to ensure that data is secure. Evident Ev Limited’s cloud service providers also ensure that only authorised personnel have access to servers where data is stored and processed.</p>

                <TextBlockHeader heading="Your right to opt out" headingStyle="cookie" />

                <p className="pb-5">As a site user, you can opt out of data collection and usage. Please see our cookie policy on the C-Capsule website for more information on how to opt out of data collection. You can also contact the DPO to request restricted processing or erasure of your data.</p>

                <p className="pb-5">As an individual in a commercial relationship with us, consent to collecting and processing data is drawn from the contractual agreement. To end the processing of data, you may contact the DPO to request a restriction of processing, such that no other communication is made with you other than for the purpose of ensuring termination of the contract. Upon termination of the contract, data will be retained in line with the retention policy set out in this policy and will only be stored in line with our obligations under the retention policy.</p>

                <p className="pb-5">As an employee or agent of a company or business in a commercial relationship with us, you have a right to restrict processing of your information by contacting the DPO. Reasonable steps will be taken alongside your principal or employer to ensure that a replacement contact at the company is made available, after which your data will be fully and permanently deleted while considering data storage and retention rules for data on the Registry.</p>

                <TextBlockHeader heading="Third Party Processors" headingStyle="cookie" />

                <p className="pb-5">We process all the data we collect through C-Capsule Limited who adhere to data protection legislation in the UK, EU, and globally. Additional safeguards are put in place by C-Capsule Limited to ensure that data is kept securely, processed in line with this policy, and protected where international transfers take place.</p>

                <p className="pb-5">C-Capsule Limited stores and processes information using an infrastructure of cloud service providers. C-Capsule Limited has ensured that it uses a cloud service provider whose compliance with data protection legislation is robust enough to meet its requirements. Safeguards such as encryption are also in place to ensure that data is secure. C-Capsule Limited’s cloud service providers also ensure that only authorised personnel have access to servers where data is stored and processed.</p>

                <TextBlockHeader heading="Data storage and retention" headingStyle="cookie" />

                <p className="pb-5">Your information is securely stored in the United Kingdom.</p>

                <p className="pb-5">For individuals in a commercial relationship with us, data that is provided to us at the start of the commercial relationship will be kept throughout the course of the relationship with us. For employees of companies in a commercial relationship with us, data is kept throughout the course of your principal’s relationship with us or until you request restriction or objection to processing of your data.</p>

                <p className="pb-5">Data on the Registry is never deleted. This is needed for our audit trail purposes. These audit purposes are for our legitimate interests and fall under the scope of lawful basis for processing relating to archiving data for historical research purposes. Such data will include usernames, names, telephone numbers, and emails that have been provided in the course of your commercial relationship with us. We will employ further safeguards of restricted processing, pseudonymisation, and invisibility of such data. <span className="font-bold text-neutral-600">You retain the right to access or request what information we have on you in the registry.</span> However, once a commercial relationship has ended, the right to rectification no longer applies as the data is historical.</p>

                <p className="pb-5">Only data on the Registry is kept in this manner, where the data is kept elsewhere, we keep your data for eighteen months, except contracts which we will maintain a copy of for our business records. We keep data for this period for dispute resolution purposes, national legislation compliance purposes, and outstanding billing purposes. After this time, data is deleted.</p>

                <p className="pb-5">We take reasonable precautions to protect personal, confidential, and non-public information. It is our policy to restrict access to confidential, non-public, and personal information to those employees who need to know such information to provide our services or as otherwise appropriate and consistent with this Privacy Policy.</p>

                <TextBlockHeader heading="Your rights" headingStyle="cookie" />

                <ul className="list-disc pl-10 pb-5">
                    <li>You have a <span className="font-bold text-neutral-600">right to be informed</span> of the data we collect, when we collect it, how we use it, and changes to the way we process your data.</li>

                    <li>You have a <span className="font-bold text-neutral-600">right of access</span> to the data we hold on you by submitting a subject access request to the DPO through the stipulated means in the next section of this policy.</li>

                    <li>You also have a <span className="font-bold text-neutral-600">right to rectify</span> data that is held by us, where there is reason to believe that such data is inaccurate.</li>

                    <li>You have a <span className="font-bold text-neutral-600">right to the erasure</span> of your data (in line with our retention policy). You have a right to restrict processing of your data.</li>

                    <li>You have a <span className="font-bold text-neutral-600">right to object to automated decision-making</span> including profiling.</li>

                    <li>Site users and employees or agents of companies in a commercial relationship with us have an additional <span className="font-bold text-neutral-600">right to object</span> to our processing of your data, please see the information above on data storage and retention. You do not have a right to data portability as our processing of your data is only for our legitimate interests.</li>

                    <li>While the right to object does not apply to individuals in a commercial relationship with us, the <span className="font-bold text-neutral-600">right to withdraw consent and restrict processing</span> in line with the right to opt out will apply.</li>

                    <li>Individuals in a commercial relationship with us also have a <span className="font-bold text-neutral-600">right to portability</span> in order to securely move and use your data across a range of services without having to provide such data again, also see the business transfers section for more information.</li>

                    <li>Individuals who have been in communication with us beyond the website but are not yet in a commercial relationship with us, have the same rights as employees of client companies.</li>
                </ul>

                <p className="mb-5">The ways in which these rights can be exercised are clearly stated throughout this policy document, and you can contact the DPO for more information or to make a request to exercise these rights.</p>

                <p className="mb-5">Additionally, employees or agents of companies in a commercial relationship with us must bear in mind, that we act as a joint controller with their principal who provided their details to us, for the purposes of fulfilling our contractual obligation, hence, the employer or principal, themselves must be contacted by you for processing of your data that does not relate to the commercial relationship, we have with them.</p>

                <TextBlockHeader heading="Subject Access Request" headingStyle="cookie" />

                <p className="mb-5">EU data subjects: Email or write to the DPO or the EU Representative.</p>

                <p className="mb-5">Data subjects in non-EU countries: Email or write to the DPO.</p>

                <p className="mb-5">It is recommended that a copy of ID is attached to your letter or email, as we cannot process a request unless we are able to identify the individual. More information may be requested for the purpose of identifying the individual to prevent unauthorised access.</p>

                <p className="mb-5">More information may also be requested with regards to the Subject Access Request itself to ensure that the request is dealt with effectively.</p>

                <p className="mb-5">While, legally, we have a month to respond to requests, we aim to acknowledge receipt of subject access requests and request additional information, if necessary, within seven business days of receiving the request. Upon the receipt of all necessary information and proper identification of the data subject, we aim to process and complete the request within a further 14 business days.</p>

                <p className="mb-5">Where there is an urgent reason for faster processing of a subject access request, the data subject must state that it is urgent and the reason for such urgency. It is recommended that urgent subject access requests be by email.</p>

                <p className="mb-5">Requests will be responded to in the same manner as it has been received unless the data subject requests otherwise in the initial or subsequent communication.</p>

                <TextBlockHeader heading="DPO" headingStyle="cookie" />

                <p className="mb-5">Name: Ben Woodhead</p>

                <p className="mb-5">Correspondence Address: C-Capsule Limited</p>

                <p className="mb-5">Church Studio<br />
                    400 Springvale Road<br />
                    Sheffield<br />
                    S10 1LP<br />
                    United Kingdom</p>

                <p className="mb-5">Email: <a href="mailto:info@c-capsule.com">info@c-capsule.com</a></p>

                <p className="mb-5 font-bold text-neutral-600">The DPO is also the UK representative for C-Capsule Limited data subjects based in the United Kingdom.</p>

                <TextBlockHeader heading="EU representative" headingStyle="cookie" />

                <p className="mb-5">Under the EU GDPR, we hereby provide the EU representative below as an additional contact point for data subjects based in the EU. Please note that the EU representative can only deal with requests relating to data subjects in the EU.</p>

                <p className="mb-5">Name: Jared Braslawsky</p>

                <p className="mb-5">Correspondence Address: De Mortel 2D, 5211 HV’s Hertogenbosch, The Netherlands</p>

                <p className="mb-5">Email: <a className="" href="mailto:eudata@pure-energi.com">eudata@pure-energi.com</a></p>

                <TextBlockHeader heading="Information Disclosure Obligations under national laws" headingStyle="cookie" />

                <p className="mb-5">Personal data held by us will be treated with the utmost confidentiality. However, where we reasonably suspect criminal activity, we will disclose such information to the appropriate authorities. Likewise, where we are bound by national laws to make certain disclosures to public authorities or regulatory bodies or bound by court order to make such disclosures in a legal proceeding, we will comply with such obligations.</p>

                <p className="mb-5">We will only inform the data subject of such a disclosure if the law or court order in question permits it.</p>

                <TextBlockHeader heading="Public Data published by other sources" headingStyle="cookie" />

                <p className="mb-5">This policy does not apply to data that is publicly available. However, where such data has been deleted/erased from the source upon the request of the data subject or to comply with a court order, law or other code of conducts/guidelines, we will also erase any duplicates of such data on our systems at the request of the data subject.</p>

                <p className="text-neutral-600 font-bold">Public Data Published by us:</p>

                <p className="mb-5 pt-5">Data may be made public by us, this may include Facility information (which stays on forever, but upon termination of our contractual relationship, it will be made clear that C-Capsule Limited are no longer being issued against the device), list of Participants and Registrants (information here will be removed upon contract termination). Such data is only made public according to the Standard terms agreed upon between you and us upon the commencement or continuation of a commercial relationship and in fulfilling our obligations in line with regulations. Where our commercial relationship comes to an end, and such data is no longer public, we will take reasonable and practical steps to ensure that processors or joint controllers whom we have shared such data with and other parties who access this data from us also erase their copies of the data. However, we cannot guarantee that such data will be made completely private due to the nature of the internet, especially as the basis of making the data public was based on the terms of our contract.</p>

                <TextBlockHeader heading="Data security and protection" headingStyle="cookie" />

                <p className="pb-5">We ensure that data is kept secure using industry standards on information security management.</p>

                <p className="pb-5">However, there is no guarantee that measures for security will always prevent unauthorised access. Where unauthorised access occurs, we will notify you, take reasonable steps to remedy the situation and to mitigate it in the future.</p>

                <p className="pb-5">Where we communicate through electronic means with you, we will also ensure that we take all reasonable steps to ensure that our medium is free from viruses and is secure. However, we cannot guarantee absolute security as no method of transmission over the internet is 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us, and you do so at your own risk.</p>

                <p className="pb-5">We also urge you to take reasonable steps to ensure that there is no unauthorised access to your data by ensuring that your devices and credentials are kept safe and private.</p>

                <TextBlockHeader heading="Breach" headingStyle="cookie" />

                <p className="pb-5">In the event of a data breach, the Information Commissioner’s Office (ICO) and/or Dutch Data Protection Authority and the affected data subjects involved will be notified. Where it is not possible to give individual notice to you, a public notice will be placed on this website.</p>

                <TextBlockHeader heading="Business transfers" headingStyle="cookie" />

                <p className="pb-5">Apart from processing with the processors, we also share data with the Central</p>

                <p className="pb-5">Issuers, your country’s Issuer, Registry Operators, Platform Operators, and the International REC</p>

                <p className="pb-5">Foundation. This will be to comply with the Foundation’s International Attribute Tracking Standard, the C-Capsule Limited Product Code, appropriate contractual obligations and national legislation.</p>

                <p className="pb-5">We may also get information from the Businesses we have commercial relationships within the course of providing services to you and them. The information we get is your name, contact details, and activities carried out between parties under contract relating to the issuance or redemption of CRUs (Carbon Removal Units). We get this information from the Central Issuers, your country’s Issuer, Registry operators, and Platform operators.</p>

                <p className="pb-5">When making such data transfers, we will ensure the security of that data by using means which are encrypted and free from viruses. We will also ensure that only data that is necessary for the transfer or processing by the recipient is transferred to comply with the data minimisation principle. Where any records of your data have been erased in compliance with this policy, we will alert any recipients of such data to delete their copy of the data.</p>

                <p className="pb-5">More information on the relationships between different Accredited Entities and Market Entities and reasons for sharing data between them are made available in the Product Code or International Attribute Tracking Standard. Data is only shared in this manner to comply with our obligations, provide our services to you, or other related entities and for our legitimate interest. Data will never be shared for the purposes of direct marketing.</p>

                <TextBlockHeader heading="Changes to this policy" headingStyle="cookie" />

                <p className="pb-5">If in the future, changes are made to this Privacy Policy, we will post the new policy on this website. If the new policy is somehow materially less restrictive or protective of your privacy than the current policy, we will not apply the less protective aspects of the revised policy to information you have previously provided to us without first obtaining your consent.</p>

                <p className="pb-5">We reserve the right to change this Privacy Policy in the future. Your continued use of this website and our services following a change in the Privacy Policy represents consent to the new policy to the fullest extent permitted by law.</p>

                <p className="pb-5">We will not process your data in any way that violates your contractual agreement with us.</p>

                <p className="pb-5">We encourage you to periodically review this Privacy Policy.</p>

                <p className="pb-5"><em>This Privacy Statement was last updated on 23 January 2023. </em></p>

                <TextBlockHeader heading="Questions" headingStyle="cookie" />

                <p className="pb-5">For questions regarding this policy or your data and how we process it, please contact the DPO listed above.</p>

                <p className="pb-5">Further details on the acceptable use of our services can be found in our Standard Terms and Conditions for the respective services we provide to you.</p>

                <TextBlockHeader heading="How to complain" headingStyle="cookie" />

                <p className="pb-5">You can contact the DPO directly to complain at <a href="mailto: info@c-capsule.com">info@c-capsule.com</a>. You can also complain to the Dutch Data Protection Authority if you are unhappy with how we have used your data or managed your complaints about your data. You can contact them by writing to them: Autoriteit Persoonsgegevens, PO Box 93374, 2509 AJ DEN HAAG or by telephone +31(0)708888500.</p>

                <p className="pb-5">You also have a right to complain to your local data protection authority where appropriate.</p>
            </div>
        </div>
    );
}

export default CookiePolicy;