const TextBlockHeader = ({ heading, headingStyle }: { heading: string, headingStyle: string }): JSX.Element => {

    let headingClasses;

    switch (headingStyle) {
        case "cookie": headingClasses = "text-2xl text-neutral-600 font-bold pb-5"
            break;
        case "home": headingClasses = "text-brand-green text-center text-3xl font-bold pt-5"
            break;
        default: headingClasses = ""
    }

    return <h1 className={headingClasses}>{heading}</h1>
}

export default TextBlockHeader;