import GenericSubSectionHeader from "./GenericSubSectionHeader";
import HalfSplitSection from "./HalfSplitSection";
import ImageWithFallback from "../ImageWithFallback";

const SectionWithImage = ({
    headerText,
    isMainHeader,
    content,
    img,
    altText,
    fallbackImg,
    isLight = false
}: {
    headerText: string;
    isMainHeader?: boolean;
    content: JSX.Element;
    img: string;
    fallbackImg: string;
    altText: string;
    isLight?: boolean;
}) => {
    return (
        <section className={`${isLight ? "bg-light-article" : ""}`}>
            <div className="container flex flex-col p-5 items-center md:flex-row sm:p-3 md:py-10">
                <HalfSplitSection
                    leftContent={<ImageWithFallback srcImg={img} fallbackImg={fallbackImg} altText={altText} />}
                    leftClasses="pb-5 md:pr-10 xl:pr-20 flex items-center"
                    rightContent={
                        <>
                            <GenericSubSectionHeader isMainHeader={isMainHeader} heading={headerText} size="text-3xl" />
                            <div className="">{content}</div>
                        </>
                    }
                    rightClasses="self-center"
                />
            </div>
        </section>
    );
};

export default SectionWithImage;
