import BlogImage from "../components/BlogImage";
import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";

//images
import isc2020_1 from "../assets/images/blog/isc2022_1.jpg";
import isc2020_2 from "../assets/images/blog/isc2022_2.jpg";

const ISC2022 = () => {
    return <div>
        <GenericSubSectionHeader isMainHeader heading="C-Capsule at the I-REC Standard Conference 2022" size="text-5xl" />

        <p className="pt-5">The inaugural International REC Standard Foundation Conference took place in Bangkok, Thailand between 1 and 2 November 2022, marking a significant milestone in global discussions on Renewable Energy Certificates (RECs) and the evolution of certification practices. This event served as a platform for stakeholders, governmental agencies, and market actors from across the globe to convene and deliberate on governance structures, upcoming Product Codes, and emerging market trends.</p>

        <BlogImage image={isc2020_2} alt="Welcome to ICS2022 Banner" />

        <p>Travis Caddy, Product Development Manager, C-Capsule had the privilege of addressing an audience on the topic of ‘Carbon Removal and Reduction Mechanisms’. He shed light on the CDR Code and C-Capsule’s global implementation efforts. Additionally, the event featured speakers such as Mourad Dahbi, Founder and CEO of Energroup LLC, and Nick Gogerty, MD of Carbon Finance Labs, and delved into C-Capsule’s application under the inaugural Methodology for Distributed Biochar. </p>

        <BlogImage image={isc2020_1} alt="Conference applause" />

        <p className="pb-5">The I-REC Standard Foundation Conference 2022 brought together global actors in renewable industries and I-REC markets.</p>

        <p className="py-5">For a detailed summary of the event, please see here: <a href="https://www.irecstandard.org/isc-2022/">https://www.irecstandard.org/isc-2022/</a></p>

    </div>

}

export default ISC2022;