import { NavLink } from "react-router-dom";

const BrandButton = ({ btnText, url, isNavLink, isDownload, whiteText }: { btnText: string, url?: string, isNavLink?: boolean, isDownload?: boolean, whiteText?: boolean }) => {
    const commonProps = {
        key: btnText,
        className: `ev-btn w-fit font-bold rounded-tr-[12px] border-2 border-brand-green px-5 py-[0.3rem] ${whiteText ? "text-white" : "text-neutral-600"}
        hover:bg-brand-green transition duration-200 ease-in-out hover:cursor-pointer
        `,
        children: btnText
    };

    if (isNavLink && url) {
        // Navigation button
        return <NavLink to={url} {...commonProps} />
    } else {
        // Dowload button
        return <a
            href={url}
            target="_blank"
            rel="noreferrer"
            {...commonProps}
        >{btnText}</a>
    }
}

export default BrandButton;
