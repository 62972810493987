import PageTitle from './PageTitle';
import { ReactNode } from 'react';

const PageHeader = ({ headerText, headerImg }: { headerText: string | ReactNode, headerImg?: string }) => {
    return (
        <>
            <article className='hidden bg-page-header h-[400px] p-10 bg-cover bg-center md:flex'
                style={{ backgroundImage: `url(${headerImg})` }}>
                <div className='container flex flex-row'>
                    <div className='w-1/2 pt-10 pr-15 flex items-center'><PageTitle title={headerText} /></div>
                    <div className='hidden w-1/2'></div>
                </div>
            </article>

            <article className='md:hidden'>
                <div className='container flex flex-row'>
                    <PageTitle title={headerText} />
                </div>
            </article>
        </>

    )
}

export default PageHeader;