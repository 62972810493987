import { ReactNode, useEffect } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

// React router does not  currently have a native solution for scroll resoration for the every router type.
// This restores that functionality

const ScrollToTop = ({ children }: { children: ReactNode; }) => {
    const location = useLocation();
    const navType = useNavigationType();
    useEffect(() => {
        // Pop navigation type is the back and forward button
        if (navType !== "POP") {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [location, navType]);
    return <>{children}</>;
}

export default ScrollToTop;