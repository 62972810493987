import { useState, useRef, useEffect } from 'react';
import chevron from '../../assets/images/right-thin-chevron.svg';
import BrandButton from './BrandButton';

type DocumentDetails = {
    title: string,
    fileSrc: string,
    aboutDocument?: string
};

const Accordion = ({ mainText, subText, doc }: { mainText: string, subText?: string | null, doc?: DocumentDetails }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const chevronOrientation = isExpanded ? "rotate-90" : "";
    const accordianHeight = isExpanded ? 'max-h-100' : 'max-h-0';
    const buttonMover = isExpanded ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-3";

    const toggleAccordion = () => {
        // Using the callback form of setState to ensure that the updated state depends on the previous state.
        setIsExpanded(prevIsExpanded => !prevIsExpanded);
    };

    // Reference to the accordion element to attach keyboard event listeners.
    const accordionRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const currAccordian = accordionRef.current;

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' || event.key === ' ') {
                toggleAccordion(); // Toggle the accordion on Enter or Space key press.
            }
        };

        // Add keyboard event listener on mount.
        if (currAccordian) {
            currAccordian.addEventListener('keydown', handleKeyDown);
        }

        // Clean up the event listener when the component unmounts.
        return () => {
            if (currAccordian) {
                currAccordian.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, []); // The empty dependency array ensures that the effect only runs on mount and unmount.


    return (
        <div
            ref={accordionRef}
            tabIndex={0}
            className='container border-b-2 p-3 cursor-pointer text-neutral-600 hover:bg-gray-50'
            onClick={toggleAccordion}
        >
            <div className="flex justify-between items-center py-1 cursor-pointer transition-transform duration-300">
                <div>
                    <span className="text-lg font-semibold">{mainText}</span>
                    {doc && <p>{subText}</p>}
                </div>

                <img className={`w-[25px] opacity-75 transform transition-transform ${chevronOrientation}`}
                    src={chevron} alt="chevron" aria-hidden>
                </img>
            </div>

            <div
                className={`mt-2 overflow-hidden transition-max-height duration-300 ${accordianHeight}`}
            >
                {!doc && <p className='pr-20'>{subText}</p>}

                {doc &&
                    <div className={`${buttonMover} transition-all transform my-5`} onClick={(e) => e.stopPropagation()}>
                        <BrandButton url={doc.fileSrc} isDownload btnText='Download document' />
                    </div>
                }

            </div>
        </div>

    );
};


export default Accordion;
