import SectionWithImage from "./UI/SectionWithImage";

// Images
import ClimateWeek_4 from "../assets/images/blog/AfricaClimateWeek_4.jpg"
import BrandButton from "./UI/BrandButton";

const FeaturedArticle = () => {
    return <div>
        <SectionWithImage headerText="Africa Climate Week 2023" altText="Ed Everson and Mutuma Marangu" img={ClimateWeek_4} fallbackImg={ClimateWeek_4} content={<>
            <p className="pt-3">On 5 September, as part of Africa Climate Week 2023, C-Capsule attended a remarkable event at the Nairobi National Museum's Mutuma Marangu Sculpture and Art Collection (TMMSAC). This event wasn't a typical art exhibition; it was a celebration of the connection between art and fighting climate change.</p>
            <div className="my-5">
                <BrandButton url="/news/article/AfricaClimateWeek" isNavLink btnText="Read More" />
            </div>
        </>}
        />
    </div>
}

export default FeaturedArticle;