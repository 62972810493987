const ImageWithFallback = ({
    srcImg,
    fallbackImg,
    altText,
    isLazy = false,
}: {
    srcImg: string;
    fallbackImg: string;
    altText: string;
    isLazy?: boolean;
}) => {
    return (
        <picture>
            <source className="rounded-md" srcSet={srcImg} type="image/webp" />
            <img
                className="rounded-md"
                src={fallbackImg}
                alt={altText}
                loading={isLazy ? "lazy" : "eager"}
            />
        </picture>
    );
};

export default ImageWithFallback;
