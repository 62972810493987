import { NavLink } from "react-router-dom";
import CookiePreferences from "./CookiePreferences";
import { useState, useEffect } from "react";
import ChangeCookiePreference from "../utilities/ChangeCookiePreference";
import { getConsent } from "../utilities/CookieConsent";

const CookieBanner = () => {

    const [isPreferencesOpen, setIsPreferencesOpen] = useState(false);
    const [showCookieBanner, setShowCookieBanner] = useState(false);

    useEffect(() => {
        // Check local storage for allowGA key
        const allowGAValue = getConsent();

        // Update showCookieBanner based on allowGA value
        setShowCookieBanner(allowGAValue === null);
    }, []);

    const acceptCookies = () => {
        setIsPreferencesOpen(false);
        ChangeCookiePreference(true);
        setShowCookieBanner(false);
    }


    return (
        <div className={`${showCookieBanner ? "flex" : "hidden"} bottom-0 bg-white drop-shadow-md z-10 fixed w-full border-t border-slate-200 p-5`}>
            <div className="container flex flex-col justify-between py-5 items-center md:flex-row">
                <p className="mr-5 pb-5 md:w-2/3 md:pb-0">We use cookies to optimise site functionality and enhance your experience. To find out more about our use of cookies, please see our <NavLink className={`underline`} to="/cookie-policy">cookie policy.</NavLink></p>
                <div className="md:w-1/3 align-self mr-5 flex items-center">
                    <button className={`text-white p-[1rem] px-5 py-2 bg-brand-green rounded-full hover:bg-brand-green-hover font-bold mr-5`} onClick={() => acceptCookies()}>Accept recommended</button>

                    <button className={`font-bold`} onClick={() => setIsPreferencesOpen(true)}>Manage Settings</button>
                </div>
            </div>
            {isPreferencesOpen &&
                <CookiePreferences isPreferencesOpen={setIsPreferencesOpen} />
            }
        </div>
    );
}

export default CookieBanner;