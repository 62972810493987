import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";
import BlogImage from "../components/BlogImage";

import Basel2023_1 from "../assets/images/blog/Basel2023_1.jpg";
import Basel2023_2 from "../assets/images/blog/Basel2023_2.jpg"
import Basel2023_3 from "../assets/images/blog/Basel2023_3.jpg"

const CarbonRemovalBasel2023 = () => {
    return <>
        <GenericSubSectionHeader isMainHeader heading="C-Capsule Attends Carbon Removal Basel 2023" size="text-5xl" />

        <p className="pt-5">On 13 April 2023 Carbon Removal Basel 2023, convened to bring together top innovators and thought leaders in field of  carbon removal. Their aim was to discuss the current state of Carbon Dioxide Removal (CDR) and explore ways to scale the industry to meet Net Zero goals. </p>

        <BlogImage image={Basel2023_1} alt="Carbon Removal Basel event flyer" credit="Carbonfuture" />

        <p>Travis Caddy, Product Development Manager, C-Capsule spoke on ‘MRV and Standards as the Foundation of a Scalable CDR Market’. Moderating the panel was Anna Lehner and other esteemed speakers included Marianne Tikkanen, Hans-Peter Schmidt, and Berta Moya.  The panel included discussions on how trustworthy standards and methodologies are crucial for building buyer confidence in the carbon removal market. The panellists discussed how the carbon removal market is working towards greater transparency and dependability through science-based methodologies and independent verification. </p>

        <BlogImage image={Basel2023_2} alt="Travis Caddy event panel" credit="Carbonfuture" caption="From left: Hans-Peter Schmidt, Travis Caddy, Berta Moya" />

        <p className="pb-5">During the panel, Travis underscored the need to build trust in the CDR market, emphasising the importance of separation. He remarked, “The way we see trust in the CDR market is separation and independence. This means having a clear separation of roles, whether it be the Standard setter, the issuing body, the registry provider, or the marketplace." Building this foundation of trust will require time and continued efforts to ensure all stakeholders are aligned towards a common goal.</p>

        <p>The Carbon Removal Basel summit served as an important reminder of the critical role trust and collaboration play in propelling the carbon removal market forward. </p>

        <BlogImage image={Basel2023_3} alt="Event panel discussions" credit="Carbonfuture" caption="From left: Marianne Tikkanen, Hans-Peter Schmidt, Travis Caddy, Berta Moya" />

        <p className="pb-5">The event was hosted by Evident and included a diverse array of guests, and in addition to C-Capsule included organisations such as the International REC Standard Foundation, Direct Air Capture Coalition, Octavia Carbon, Cella, Olsuswa Energy Limited, Rethinking Removals, The Open Air Collective, Climate Action Platform for Africa (CAP-A), Africa Climate Ventures, Rubicon, International Emissions Trading Association (IETA), and CUR8, amongst others.</p>

        <p className="pb-5">You can watch the full discussion <a href="https://www.youtube.com/watch?v=2jQfQLOiE9g&feature=youtu.be">here:</a></p>


        <div className="pb-10">
            <iframe
                className="w-full aspect-video"
                src="https://www.youtube.com/embed/2jQfQLOiE9g?si=KBlxUjBWSBgMzgiK"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            ></iframe>
        </div>
    </>

}

export default CarbonRemovalBasel2023;