import { useParams } from 'react-router-dom';
import PageHeader from './UI/PageHeader';
import MoreArticles from './MoreArticles';
import MoreArticlesSidebar from './MoreArticlesSidebar';

// individual articles
import AfricaClimateWeek from '../articles/AfricaClimateWeek';
import CarbonRemovalBasel2023 from '../articles/CarbonRemovalBasel2023';
import ISC2022 from '../articles/ISC2022';
import ITN from '../articles/ITN';
import TEDTalk from '../articles/TEDTalk';
import RevolutionisingCarbonMarket from '../articles/RevolutionisingCarbonMarket';
import Istanbul2022 from '../articles/Istanbul2022';
import Consultation from '../articles/Consultation';

// images
import FAQBanner from "../assets/images/banners/faq-banner.webp";

function NewsArticle() {
    const { articleId } = useParams();

    return (
        <div className='pb-10'>
            <PageHeader headerText="News & Events" headerImg={FAQBanner} />
            <div className="container p-5 pt-10 flex flex-col xl:flex-row text-neutral-600">
                <div className='pr-5 w-full 2xl:pr-24'>
                    {articleId === "AfricaClimateWeek" && <AfricaClimateWeek />}
                    {articleId === "CarbonRemovalBasel2023" && <CarbonRemovalBasel2023 />}
                    {articleId === "ISC2022" && <ISC2022 />}
                    {articleId === "ITN" && <ITN />}
                    {articleId === "ted-by-nick-gogerty" && <TEDTalk />}
                    {articleId === "Consultation" && <Consultation />}
                    {articleId === "revolutionising-the-carbon-market" && <RevolutionisingCarbonMarket />}
                    {articleId === "travis-caddy-at-IREC-Istanbul" && <Istanbul2022 />}
                </div>

                <div className='hidden xl:flex'>
                    <MoreArticlesSidebar />
                </div>

                <div className='xl:hidden' >
                    <MoreArticles insideArticle />
                </div>
            </div>
        </div>
    );
}

export default NewsArticle;
