import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";
import BlogImage from "../components/BlogImage";

import traviscaddy from "../assets/images/blog/TravisCaddy.png"

const RevolutionisingCarbonMarket = () => {
    return <>
        <GenericSubSectionHeader isMainHeader heading="How C-Capsule is Revolutionising the Carbon Market" size="text-5xl" />

        <BlogImage image={traviscaddy} alt="Travis Caddy" caption="Travis Caddy" />

        <p className="pt-5">In an exclusive interview with Echoz, a pioneering organisation that helps companies achieve their  Net Zero journey, Travis Caddy shared his insights into how the standard for C-Capsule  is poised to helped reshape the carbon market. Read the full interview and find out how C-Capsule aims to bring simplicity, speed, flexibility, and cost-effectiveness to the certification of carbon dioxide removals on the Echoz website <a className="font-bold hover:underline" href="https://rb.gy/u2vkt">here.</a></p>

    </>

}

export default RevolutionisingCarbonMarket;