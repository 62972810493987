import GenericSubSectionHeader from "../components/UI/GenericSubSectionHeader";
import BlogImage from "../components/BlogImage";

import istanbul2022panel from "../assets/images/blog/Istanbul2022.png"

const Istanbul2022 = () => {
    return <>
        <GenericSubSectionHeader isMainHeader heading="Travis Caddy at IREC Istanbul 2022" size="text-5xl" />

        <BlogImage image={istanbul2022panel} alt="Travis Caddy and panel at IREC Istanbul 2022" />

        <p className="pt-5">On 10 October 2022 Travis Caddy took the stage at an event organised by Foton ECO. As the Local I-REC Issuer in Türkiye, Foton ECO plays a crucial role in facility registration and issuance within the IREC Electricity Code. At the event, Travis emphasised the pressing need for durable CDR and showcased how C-Capsule is stepping up to meet this challenge. You can view the complete talk here: </p>

        <div className="py-10">
            <iframe className="w-full aspect-video" src="https://www.youtube.com/embed/vSxNdksRDwY?si=wTBJq5wtPoROgJ7-" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

    </>

}

export default Istanbul2022;